import React, { useContext } from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import BiolabPdf from "components/BiolabPdf";
import { AppContext } from "../../context/app-context";
import API from "api";
import { CONFIG } from "constant";

const PDFLayout = (props) => {
  const { testPdf, testsLab, setShowPdf } = props;
  const appContext = useContext(AppContext);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover" style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }}>
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Test Results - {testPdf ? testPdf.name : ""}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer px-0">
              <PDFViewer className="pdfView">
                {CONFIG.isWhiteLabel ? (
                  <BiolabPdf
                    test={testPdf}
                    testsLab={testsLab}
                    parseTestResultForPDF={appContext.parseTestResultForPDF}
                  />
                ) : (
                  <PDF test={testPdf} testsLab={testsLab} parseTestResultForPDF={appContext.parseTestResultForPDF} />
                )}
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PDFLayout;
