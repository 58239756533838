import FilledCheckBox from "components/CheckBox/FilledCheckBox/FilledCheckBox";
import React, { useState } from "react";
import RadioButton from "../RadioButton/RadioButton";

const CheckBoxInput = (props) => {
  const { options, label, input, handleButtonChange, disabled } = props;
  const handleChange = (e, m) => {
    let arr = input.split(",");
    if (e.target.checked) {
      arr.push(m.value);
    } else {
      arr = arr.filter((f) => f !== m.value);
    }
    handleButtonChange(arr.length > 0 ? arr.join(",") : "");
  };
  return (
    <>
      <div className="form-group-wrapper mt-3">
        <label className="status">{label}</label>
        <div className="py-1 checkbox-list">
          {options.map((m) => (
            <FilledCheckBox
              label={m.label}
              disabled={disabled}
              id={m.id}
              isChecked={input.includes(m.value)}
              handleChange={(e) => handleChange(e, m)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CheckBoxInput;
