import React, { useState, useMemo, useRef, useCallback } from "react";
import HeaderItem from "components/Table/HeaderItem";
import { PAGE_LIMIT, MESSAGE_TYPE, RESULT_COLOR, RESULT_VALUE } from "constant";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { DataStore } from "@aws-amplify/datastore";
import { Test } from "models";
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GQAPI from "views/GraphQL/gqApi";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import MFPagination from "components/Pagination/MFPagination";
import { AppContext } from "context/app-context";
import PDFLayout from "components/PDF/PDFLayout";
import {
  changeSort,
  getCrewsName,
  formatTest,
  setPhoneNo,
  formatPhoneNumber,
  calculateTdWidth,
  sortingFilterInLC,
  formatUTCDate,
  sortTestList,
  connectedIDInLocalStorage,
} from "utils";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import { debounce } from "debounce";
import moment from "moment";
import Loader from "components/Loader/Loader";
import Icon from "components/Icon";

function ProcessedTestListApiNew() {


  console.log('IN-ProcessedTestListApiNew' )
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 130, 12);

  const appContext = useContext(AppContext);
  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filteredTests, setFilteredTests] = useState([]);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const labID = window.location?.pathname?.split('/').pop(); 
  const [filter, setFilter] = useState({ status: "Processed", labID: labID || "L" });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Show",
    "Result",
    "Test Type",
    "Accession No",
    "Tester",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "createdAt",
    "updatedAt",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    "site_name",
    "status",
    "result",
    "test_type",
    "referenceID",
    "tester_name",
    "isVaccinated",
    "capacity",
    "whiteGlove",
    "patternTestAnswer",
  ];

  const { filterDates } = TimeFilter;

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const filterTests = (searchTerm, searchInput) => {
    let newTerm;
    switch (searchTerm) {
      case "date_created":
        newTerm = "createdAt";
        break;
      case "date_updated":
        newTerm = "updatedAt";
        break;
      case "crew_id":
        newTerm = "employeeID";
        break;
      case "show":
        newTerm = "site_name";
        break;
      default:
        newTerm = searchTerm;
    }
    return setFilteredTests(tests.filter((test) => test[newTerm].includes(searchInput)));
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }

        if (key === "Show") {
          return obj.site_name && obj.site_name.toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "referenceID") {
          return obj[key] && obj["referenceID"].toString().includes(filters[key]);
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    if (appContext.testFilter && appContext.testFilter.location === "processedtests") {
      setFilter(appContext.testFilter.filter);
      setShowFilter(true);
    }
    loadPrevSorting();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (appContext.eventObject && appContext.eventObject !== 0) {
      const eventType = appContext.eventObject.opType;
      const model = formatTest(appContext.eventObject.element);

      if (model.status !== "Processed" && model.test_type !== "PCR") return;
      const newList = [...filteredTests];

      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length) {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext?.eventObject]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["processedtests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const loadPrevSorting = () => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.processedtests.sortBy) {
      setSortDescending(sortingFilters.processedtests.sortDescending);
      setSortBy(sortingFilters.processedtests.sortBy);
    }
  };

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    try {
      const filterParam = params;
      //const connectedID = labID || "L";
      Object.assign(filterParam, { page: page, status: "Processed", labID: labID });

      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }
      const pgList = await GQAPI.getTestListFromPG(filterParam);
      setLoading(false);
      const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
      }
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "processedtests", filter, timeFilter });
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const handleSendEmail = async () => {
    const selectedTest = filteredTests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    const ttlEmails = params.length;
    appContext.showSuccessMessage(`Email has been successfully sent to ${ttlEmails} Crew Member ${ttlEmails > 1 ? "s" : ""}`);
    setCheckboxes([]);
    await GQAPI.sendEmailToPendingRelease(params);
    
  };

  const updateTestResult = async (result) => {
    console.log("result in updateTestResult::" , result)
    const updateSelectedTestResult = filteredTests.filter((test) => checkboxes.indexOf(test.id) !== -1);
    console.log("Checkbox", updateSelectedTestResult);
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);

      const params = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          result: RESULT_VALUE[result.toLowerCase()],
          status: result === "positive" ? "Pending" : "Processed",
          timerStatus: result === "positive" ? "Pending" : "Processed",
          resultDateTime: moment().utc().toISOString(),
          resultDate: moment().utc().format("YYYY-MM-DD"),
          StampBy: appContext.user.sub,
          StampByName: appContext.user.name,
          sequenceNo: t.sequenceNo,
        };
      });

      await GQAPI.updateTestResult(params);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been resulted ${result}` , true);
      setLoading(false);

    } catch (err) {
      setLoading(false);
      console.log("Err", err);
    }
   
      
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
    setCheckboxes([]);

    
  };

  function TableRow({ test }) {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left", width: "50px", maxWidth: "50px", mainWidth: "50px" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="ellipsis">{formatUTCDate(test.createdAt)}</td>
        <td className="ellipsis">{formatUTCDate(test.updatedAt)}</td>
        <td className="ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.site_name}>
          {test.site_name}
        </td>
        {/* <td className="ellipsis" style={{ textAlign: "center" }} title={test.status}>
          {test.status}
        </td> */}

        <td className="ellipsis" style={{ textAlign: "center" }} title={appContext.parseResult(test)}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
              textDecoration: "underline",
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type}>
          {test.test_type}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.barcode}>
          {test.barcode}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name}>
          {test.tester_name}
        </td>
        
        <td>
          <Link
            onClick={handleSaveFilter}
            to={{
              pathname: "/lab/test/" + labID + "/" + test.id, state: { labPortal: true , test: test},
            }}
          >
            View
          </Link>
        </td>
      </tr>
    );
  }

  const handleChangePage = (page) => setCurrentPage(page);

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Processed Tests ({totalRecord})
                </Card.Title>

                <p className="card-category mb-3">History of Processed Tests</p>
                <div className="buttonHeader">
                  {/* <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button> */}
                    <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                 {/* Date Range */}
                 <FilterSelect setTimeFilter={setTimeFilter} />
                    <Icon
                        handleClick={async () => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                  {/* <Button
                    className="headerButton"
                    onClick={() => setShowResultModal(true)}
                    disabled={checkboxes.length === 0}
                  >
                    Change Result
                  </Button> */}
                    <Icon
                            handleClick={() => setShowResultModal(true)}
                            title={"Change Result"}
                            label={"Change Result"}
                            iconType={"resultsIcon"}
                            disabled={checkboxes.length === 0}
                          />
                  {/* <Button className="headerButton" onClick={handleSendEmail} disabled={checkboxes.length === 0}>
                    Send Email
                  </Button> */}
                    <Icon
                          handleClick={handleSendEmail}
                          disabled={checkboxes.length ? false : true}
                          title={"Email"}
                          label={"Send Email"}
                          iconType={"messageIcon"}
                        />
                  {filteredTests.length > 0 && (
                    <>
                      <ExportToExcelData
                        items={filteredTests}
                        appContext={appContext}
                        selectedItem={selectedItem}
                        filter={{ ...filter, status: "Processed", labID: labID || "L" }}
                        timeFilter={timeFilter}
                        title="Processed Tests"
                        sortBy={sortBy}
                        setLoading={setLoading}
                      />
                      <TestPDFLayout
                        tests={filteredTests}
                        filter={filter}
                        selectedItem={selectedItem}
                        timeFilter={timeFilter}
                        title="Processed Tests"
                        appContext={appContext}
                        sortBy={sortBy}
                      />
                    </>
                  )}
                </div>

                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={false} />
                )}

              <MFPagination 
               timeFilter={timeFilter}
              currentPage={currentPage} 
              handleChangePage={handleChangePage} 
              totalPages={totalPages}
              showSelectedRecord
              totalSelected={checkboxes.length}
              />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Collected"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="updatedAt"
                          title="Date Processed"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="firstName"
                          title="First Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="lastName"
                          title="Last Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="dob"
                          title="DoB"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="email"
                          title="Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="phoneNumber"
                          title="Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="siteID"
                          title="Show"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        {/* <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        /> */}
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="result"
                          title="Result"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="test_type"
                          title="Test Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="barcode"
                          title="Accession No"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="tester_name"
                          title="Tester"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th
                          className="border-0 left text-grey"
                          style={{ fontWeight: "bold", width: "80px", maxWidth: "80px" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
                {!loading && tests.length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    <h4>There are no tests Completed to View</h4>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showResultModal && (
          <ChangeResultModal handleSave={updateTestResult} handleClose={() => setShowResultModal(false)} />
        )}
        {loading && <Loader />}
      </Container>
    </>
  );
}

export default ProcessedTestListApiNew;
