import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button, Container } from "react-bootstrap";
import { useEffect } from "react";
import TableRow from "./TableRow";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import LabBarCodeAlertSetting from "views/TestTypeSetting/LabBarCodeAlertSetting";
import API from "api";
import { AppContext } from "context/app-context";
import { Bounce } from "react-activity";
import HeaderItem from "../../components/Table/HeaderItem";
import { calculateTdWidth } from "../../utils";
import NewEmployeeImportModal from "components/Modal/NewEmployeeImportModal";
import Loader from "../../components/Loader/Loader";
import TestResultSetting from "./TestResultSetting";
import { CONFIG } from "constant";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [openImportModal, setOpenImportModal] = useState(false);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 5);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const [editObj, setEditObj] = useState(null);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setList(await API.getTestTypes());
  };

  const handleEdit = (item) => {
    setEditObj(item);
  };

  const handleSave = async (item) => {
    const obj = { ...item };
    setEditObj(null);
    setLoading(true);
    try {
      await API.updateTestTypes(obj);
      setLoading(false);
      setList(await API.getTestTypes());
      appContext.showSuccessMessage("Test Type update successfully");
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setEditObj(null);
  };

  return (
    <Container fluid>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="12">
                      <Card.Title>
                        <div>Test Settings</div>
                      </Card.Title>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr">
                      <thead ref={componentRef}>
                        <tr>
                          <th></th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="name">Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Total Time</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="First Alert">First Alert</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Second Alert">Second Alert</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Second Alert">Valid duration</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, i) => {
                          return <TableRow key={i} item={item} handleEdit={handleEdit} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {CONFIG.isWhiteLabel ? <TestResultSetting /> : <LabBarCodeAlertSetting />}
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {editObj && <TestTypeModal item={editObj} handleSave={handleSave} handleClose={handleClose} />}
    </Container>
  );
};

export default TestTypeSetting;
