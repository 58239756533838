import api from "api";
import EditIcon from "assets/img/edit-icon.png";
import TrashIcon from "assets/img/trash-icon.png";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import { currencyformatter, TABLE_COLUMN_TYPES, TABLE_QUICK_TOOLS } from "constant";
import useResizeObserver from "constants/hooks/useResizeObserver";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Form, Table } from "react-bootstrap";
import {
  calculateTdWidth,
  capitalizeLetter,
  convertToUpper,
  personalisationInLC,
  formatDate,
  formatDateMDY,
  tdEmail,
  tdPhone,
} from "utils";
import HeaderItem from "./HeaderItem";

const MainTable = (props) => {
  const {
    columns,
    rows,
    individualRowCssClass,
    handleSaveDragAndResize,
    personalisationKey,
    draggable,
    resizable,
    flipSort,
    sortBy,
    sortDescending,
    widthToSkip,
    tools = [],
    dropDownOptions,
    customDropDownOptsForRow,
    handleDropDownClick,
    selectedRows = [],
    handleCellClick = null,
    cssClass,
    customColumnCellRenderer,
    parentKey,
  } = props;

  const [columnToBeSwapped, setColumnToBeSwapped] = useState(null);
  const [reset, setReset] = useState(false);
  const [width, setWidth] = useState(0);
  const resizeableTable = useRef();
  const tableWrapperRef = useRef();
  const appContext = useContext(AppContext);

  //Screen Resolution Change
  useEffect(() => {
    handleScreenWidthChange();
  }, [tableWrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
    };
  });

  const handleScreenWidthChange = () => {
    if (tableWrapperRef.current) setWidth(tableWrapperRef.current.offsetWidth);
  };

  const tdWidth = calculateTdWidth(
    width - (widthToSkip || 0),
    columns.length > 0 ? columns.filter((p) => p.isCheck).length : 2
  );

  const handlePersonalization = async (data) => {
    if (!personalisationKey) return;
    handleSaveDragAndResize([...data]);
    const personalisationData = personalisationInLC.saveAs(data, personalisationKey);
    personalisationInLC.save(JSON.stringify(personalisationData));
    await api.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  useEffect(() => {
    if (reset) {
      setTimeout(() => setReset(false), 100);
    }
  }, [reset]);

  // Column Resizing
  let dimensions = null;

  if (resizable) dimensions = useResizeObserver(resizeableTable);

  useEffect(() => {
    handleScreenWidthChange();
    if (dimensions) {
      const props = { columns, whiteSpaceVisible: width - dimensions.width };
      handleColumnResizerDelay(props);
    }
  }, [dimensions]);

  const handleColumnResizerDelay = useCallback(
    debounce((props) => {
      handleColumnResizer(props);
    }, 200),
    []
  );

  const handleColumnResizer = (props) => {
    const { columns, whiteSpaceVisible } = props;
    if (!resizeableTable || !resizeableTable.current?.childNodes?.length > 0) return;
    if (!columns || columns.length === 0) return;

    const headerCells = resizeableTable.current?.childNodes[0]?.childNodes[0]?.childNodes;
    if (!headerCells || !headerCells?.length > 2) return;

    let eachColumnAdditionalWidth = 0;
    if (Math.floor(whiteSpaceVisible) > 4) {
      eachColumnAdditionalWidth = Math.floor(whiteSpaceVisible / (headerCells.length - 2));
    }
    let personalizeToSave = [...columns];
    for (let i = 1; i < headerCells.length - 1; i++) {
      const colIndex = personalizeToSave.findIndex(
        (column) => column.title?.toLowerCase() === headerCells[i].childNodes[0].innerText?.toLowerCase()
      );
      const obj = {
        ...personalizeToSave[colIndex],
        width: headerCells[i].childNodes[0].offsetWidth + eachColumnAdditionalWidth,
      };
      personalizeToSave.splice(colIndex, 1, { ...obj });
    }
    setReset(true);
    handlePersonalization(personalizeToSave);
  };

  // Draggable Column
  const handleDragStart = (e) => {
    const targetObj =
      columns.find((col) => col.isCheck && col.title.toLowerCase() === e.target.innerText.toLowerCase()) || null;
    setColumnToBeSwapped(targetObj);
  };

  const handleColumnChange = (e) => {
    const targetDestinationObj = columns.find(
      (col) => col.isCheck && col.title.toLowerCase() === e.target.innerText.toLowerCase()
    );

    if (!columnToBeSwapped || !targetDestinationObj) return;
    if (columnToBeSwapped.id === targetDestinationObj.id) return;

    let personalisationData = [...columns];
    const subjectIndex = personalisationData.findIndex((col) => col.id === columnToBeSwapped.id);
    const targetIndex = personalisationData.findIndex((col) => col.id === targetDestinationObj.id);

    const temp = personalisationData.splice(subjectIndex, 1)[0];
    personalisationData.splice(targetIndex, 0, temp);
    handlePersonalization(personalisationData);
  };

  const handleClickCall = (item, row) => {
    handleCellClick && handleCellClick(item.itemKey, row);
  };

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const tdFormat = (item, row) => {
    switch (item.type) {
      case TABLE_COLUMN_TYPES.shortDate:
        return formatDateMDY(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.longDate:
        return formatDate(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.currency:
        return currencyformatter.format(row[item.itemKey] || 0);

      case TABLE_COLUMN_TYPES.upperCaseText:
        return convertToUpper(row[item.itemKey] || "");

      default:
        return row[item.itemKey] ? capitalizeLetter(row[item.itemKey].toString()) : "";
    }
  };

  const renderTd = (item, row) => {
    switch (item.type) {
      case TABLE_COLUMN_TYPES.email:
        return tdEmail(row[item.itemKey]);

      case TABLE_COLUMN_TYPES.phone:
        return tdPhone(row[item.itemKey]);

      default:
        return (
          <td
            key={item.id}
            className={`ellipsis ${item.cssClass ?? ""}`}
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
              color: `${item.colorObj ? item.colorObj[row[item.itemKey]] : ""}`,
            }}
            onClick={() => handleClickCall(item, row)}
            title={tdFormat(item, row)}
          >
            {tdFormat(item, row)}
          </td>
        );
    }
  };

  const TableRow = ({ row, index }) => {
    return (
      <tr key={index} className={`${individualRowCssClass ? individualRowCssClass(row) : ""}`}>
        <td className={`ellipsis tools-td ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`}>
          <div className="d-flex align-items-center w-100 column-gap-10">
            {tools.includes(TABLE_QUICK_TOOLS.checkbox) && (
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  checked={selectedRows.indexOf(row.id) !== -1}
                  value={row.id}
                  onChange={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.checkbox, row, event)}
                />
              </Form.Check>
            )}
            {tools.includes(TABLE_QUICK_TOOLS.edit) && (
              <img
                src={EditIcon}
                alt="edit icon"
                width="18"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                id={row.id}
                onClick={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.edit, row, event)}
              />
            )}
          </div>
        </td>

        {columns.map((col) => col.isCheck && renderTd(col, row))}

        <td
          className={`icon tools-td ${rows.length === 1 ? "single-row-dropdown-menu" : ""} ${
            tools.includes(TABLE_QUICK_TOOLS.delete) || customDropDownOptsForRow || dropDownOptions
              ? "tools-available"
              : "tools-unavailable"
          }`}
        >
          {((customDropDownOptsForRow && customDropDownOptsForRow.length > 0) ||
            (dropDownOptions && dropDownOptions.length > 0)) && (
            <DropdownMenu
              options={customDropDownOptsForRow ? customDropDownOptsForRow(row) : dropDownOptions}
              handleDropDownOptions={(type) => handleDropDownClick(type, row)}
            />
          )}

          {tools.includes(TABLE_QUICK_TOOLS.delete) && (
            <img
              src={TrashIcon}
              alt="trash icon"
              width="15"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={row.id}
              onClick={(event) => handleCellClick && handleCellClick(TABLE_QUICK_TOOLS.delete, row, event)}
            />
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div
        ref={tableWrapperRef}
        className={`table-responsive pendingReleaseTable ${resizable ? "empClaimTable" : ""} ${cssClass || ""}`}
      >
        <Table ref={resizeableTable} className="user-table employeeTableWrapper">
          <thead>
            <tr>
              <th className={`tools-td ${tools.length > 0 ? "tools-available" : "tools-unavailable"}`} />
              {columns.map(
                (item, i) =>
                  item.isCheck && (
                    <HeaderItem
                      key={i}
                      draggable={draggable}
                      width={!resizable && tdWidth}
                      // minWidth={resizable && tdWidth}
                      innerDivWidth={resizable && (reset ? `${item.width}px` : item.width)}
                      handleDrop={handleColumnChange}
                      handleDragStart={handleDragStart}
                      ItemKey={item.itemKey}
                      title={item.title}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned={headerAlign(item.textAlign)}
                      cssClass={resizable ? "claimTableTH" : ""}
                    />
                  )
              )}
              <th
                className={`tools-td ${
                  tools.includes(TABLE_QUICK_TOOLS.delete) || customDropDownOptsForRow || dropDownOptions
                    ? "tools-available"
                    : "tools-unavailable"
                }`}
              />
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <TableRow key={index} row={row} index={index} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MainTable;
