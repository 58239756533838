import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CONFIG, RESULT_VALUE } from "constant";
import Select from "react-select";
import GQAPI from "views/GraphQL/gqApi";
import moment from "moment";
import Loader from "components/Loader/Loader";

const ChangeResultModal = (props) => {
  const { title, handleSave, handleClose, appContext, tests, callback } = props;
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [resultTypes, setResultTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes(client.resultTypes.results.map((m) => ({ value: m, label: m })));
      }
    }
  }, [appContext.clients]);

  const onHandleSave = async () => {
    if (!result) {
      setError("Please select the result type");
      return;
    }
    if (confirmText !== "confirm") {
      setError("Please enter confirm in input");
      return;
    }

    if (handleSave) {
      handleSave();
      handleClose();
    }
    
    setLoading(true);

    try {
      if (!loading) setLoading(true);
      const params = tests.map((t) => {
        return {
          id: t.id,
          result: RESULT_VALUE[result.toLowerCase()],
          status: CONFIG.isWhiteLabel ? "Processed" : result === "positive" ? "Pending" : "Processed",
          timerStatus: CONFIG.isWhiteLabel ? "Processed" : result === "positive" ? "Pending" : "Processed",
          resultDateTime: moment().utc().toISOString(),
          resultDate: moment().utc().format("YYYY-MM-DD"),
          StampBy: appContext.user.sub,
          StampByName: appContext.user.name,
          sequenceNo: t.sequenceNo,
        };
      });
      await GQAPI.updateTestResult(params);

      appContext.showSuccessMessage(`${tests.length} test${tests.length === 1 ? "" : "s"} has been resulted ${result}`);
    } catch (err) {
      console.log("Error", err);
    }
    setLoading(false);

    if (callback) {
      callback();
    }

    handleClose();
    return;
    //onChangeType()
  };

  const onChangeType = (e) => setResult(e.target.value);

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title || "Change Test Results"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {loading && <Loader />}
          {CONFIG.isWhiteLabel ? (
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Result: </label>
              <Select
                className="w-100 siteSelector"
                options={resultTypes}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={result ? { value: result, label: result } : null}
                placeholder="Select Result"
                onChange={(e) => setResult(e.value)}
              />
            </div>
          ) : (
            <>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Positive </label>
                <Form.Check>
                  <Form.Check.Input
                    id="positive"
                    value="positive"
                    name="resultGroupList"
                    type="radio"
                    checked={result === "positive"}
                    onChange={onChangeType}
                  />
                  <Form.Check.Label htmlFor="positive" className="px-2 scheduleLabel">
                    Positive
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Negative: </label>
                <Form.Check>
                  <Form.Check.Input
                    id="negative"
                    value="negative"
                    name="resultGroupList"
                    type="radio"
                    checked={result === "negative"}
                    onChange={onChangeType}
                  />
                  <Form.Check.Label htmlFor="negative" className="px-2 scheduleLabel">
                    Negative
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Inconclusive: </label>
                <Form.Check>
                  <Form.Check.Input
                    id="Inconclusive"
                    value="Inconclusive"
                    name="resultGroupList"
                    type="radio"
                    checked={result === "Inconclusive"}
                    onChange={onChangeType}
                  />
                  <Form.Check.Label htmlFor="Inconclusive" className="px-2 scheduleLabel">
                    Inconclusive
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </>
          )}
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Type confirm: </label>
            <input
              value={confirmText}
              className="modalInput"
              placeholder="confirm"
              style={{
                width: "100%",
              }}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </div>

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeResultModal;
