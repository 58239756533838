import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { formatPhoneNumber, formatZipCode, formatDateOfBirthDOB, formatDateMDY, formatAddress } from "utils";
import API from "api";
import bioMobileLogo from "assets/img/biolab_mobile.png";
import ArialRegular from "assets/fonts/arial.ttf";
import ArialBold from "assets/fonts/arial_bold.ttf";
import tickIcon from "assets/img/black-check-tick-icon.png";
import { SYMPTOMS } from "constant";
import { getAMPM } from "utils";
import { formatPhone } from "utils";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialRegular, fontWeight: "normal" },
    { src: ArialBold, fontWeight: "bold" },
  ],
});

const BioLabRequisitionPDF = ({ test, lab, signature, demographics }) => {
  const [labInfo, setLabInfo] = useState();
  const [clientInfo, setClientInfo] = useState();

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["a", "b", "c", "d"],
    datasets: [
      {
        data: [300, 50, 100, 50],
        backgroundColor: ["red", "white"],
        hoverBackgroundColor: ["red", "white"],
      },
    ],
  };

  useEffect(() => {
    if (test?.labID) {
      getLabByID(test);
    }

    if (test?.clientID) {
      getClientByID(test);
    }
  }, [test]);

  const getLabByID = async (test) => {
    if (!lab) {
      let labModel = await API.getLabByID(test?.labID);
      setLabInfo(labModel);
    }
  };

  const getClientByID = async (test) => {
    let clientModal = await API.getClientById(test?.clientID);
    setClientInfo(clientModal);
  };

  const parseClientAddress = (client) => {};

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  const formatIDNumber = () => {
    const val = demographics?.idNumber || demographics?.id_number || "00000";
    return /^[0]+$/.test(val) ? "" : val;
  };

  return (
    <Document title={`${demographics?.firstName} ${demographics?.lastName}`}>
      <Page size="A4" style={styles.page}>
        <View style={{ flex: 0.8, flexDirection: "row", borderWidth: 2, padding: 0, alignItems: "" }}>
          <View style={{ flex: 1, top: 0, bottom: 0, position: "relative" }}>
            <Text
              style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              CLIENT INFORMATION
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center", margin: "10 10" }}>
              <View style={styles.infoCol}>
                <Text style={styles.infoText}>CLIENT:</Text>
                <Text style={styles.infoText}>PHYS:</Text>
                <Text style={styles.infoText}>ADDRESS:</Text>
                <Text style={{ ...styles.infoText, color: "white" }}>.</Text>
                <Text style={styles.infoText}>TEL:</Text>
                <Text style={styles.infoText}>FAX:</Text>
              </View>
              <View style={styles.infoCol}>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{clientInfo?.name || "-"}</Text>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{labInfo?.lab_director || "-"}</Text>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{clientInfo?.street || ""}</Text>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{formatAddress(clientInfo)}</Text>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{`${formatPhone(
                  clientInfo?.phone_number || ""
                )}`}</Text>
                <Text style={{ ...styles.infoText, paddingLeft: "5px" }}>{`${
                  formatPhone(clientInfo?.fax_number) || "-"
                }`}</Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.hr, height: 135 }} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              LAB TEST REQUISITION
            </Text>
            <View style={{ textAlign: "center", padding: "2px 5px 5px 5px" }}>
              <Image
                style={{ width: "150px", height: "30px", left: 75, objectFit: "contain", textAlign: "center" }}
                src={bioMobileLogo}
              />
              {/* <Text style={{ fontWeight: "bold", fontSize: 30 }}>{labInfo?.name}</Text> */}
              <Text style={{ fontSize: 12, margin: "0px 2px" }}>{`${labInfo?.street || ""} \n ${
                labInfo?.city_state_zip || ""
              }`}</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", margin: "2px 0px" }}>
              <View style={styles.infoCol}>
                <Text style={styles.infoText}>TEL:</Text>
              </View>
              <View style={styles.infoCol}>
                <Text style={styles.infoText}>{`${formatPhone(labInfo?.phone_number)}`}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <View style={styles.infoCol}>
                <Text style={styles.infoText}>FAX:</Text>
              </View>
              <View style={styles.infoCol}>
                <Text style={styles.infoText}>{`${formatPhone(labInfo?.fax_number)}`}</Text>
              </View>
            </View>
            {labInfo && labInfo.logoSrc && (
              <View style={{ textAlign: "left", padding: 10, position: "absolute", top: "18px" }}>
                <Image style={{ width: "50px", height: "50px", top: 0, objectFit: "contain" }} src={labInfo.logoSrc} />
              </View>
            )}
          </View>
        </View>
        <View style={{ flex: 1.2, flexDirection: "row", borderWidth: 2, padding: 0, alignItems: "" }}>
          <View style={{ flex: 1, top: 0, bottom: 0, position: "relative" }}>
            <Text
              style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: 11,
                fontWeight: "bold",
              }}
            >
              PATIENT'S INFO (NAME MUST MATCH INSURANCE)
            </Text>
            <View style={{ flexDirection: "row", flex: 0.7, borderBottomWidth: 1 }}>
              <View style={{ flex: 1 }}>
                <Text style={{ ...styles.label, paddingLeft: "5px" }}>LAST NAME</Text>
                <Text style={{ fontSize: 10, paddingLeft: "5px" }}>{demographics?.lastName}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ ...styles.label, paddingLeft: "5px" }}>First NAME</Text>
                <Text style={{ fontSize: 10, paddingLeft: "5px" }}>{demographics?.firstName}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, flex: 0.7 }}>
              <Text style={{ ...styles.label, paddingLeft: "5px" }}>ADDRESS</Text>
              <Text style={{ fontSize: 10, paddingLeft: "5px" }}>{demographics?.street || ""}</Text>
            </View>
            <View style={{ flexDirection: "row", flex: 0.7, borderBottomWidth: 1 }}>
              <View style={{ flex: 1.7 }}>
                <Text style={{ ...styles.label, paddingLeft: "5px" }}>CITY</Text>
                <Text style={{ fontSize: 10, paddingLeft: "5px" }}>{demographics?.city || ""}</Text>
              </View>
              <View style={{ flex: 0.5, borderLeft: "1px solid black" }}>
                <Text style={{ ...styles.label, textAlign: "center" }}>STATE</Text>
                <Text style={{ fontSize: 10, textAlign: "center" }}>{demographics?.state || ""}</Text>
              </View>
              <View style={{ flex: 0.9, borderLeft: "1px solid black" }}>
                <Text style={{ ...styles.label, textAlign: "center" }}>ZIP</Text>
                <Text style={{ fontSize: 10, textAlign: "center" }}>{demographics?.zip || ""}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", flex: 2 }}>
              <View style={{ flex: 1, borderRightWidth: 1 }}>
                <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", ...styles.label }}>
                  BILLING
                </Text>
                <View style={{ flex: 1, padding: "0px 10px", textAlign: "center" }}>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>CLIENT</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>HRSA</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>INSURANCE</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>MEDI-CAL</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>MEDICARE</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>PATIENT</Text>
                  </View>
                  <View style={styles.billingList}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>CASH/CHECK</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <View style={{ borderBottomWidth: 1, flex: 1 }}>
                  <Text style={{ ...styles.label, fontWeight: "bold" }}>INSURANCE COMPANY</Text>
                  <Text style={{ fontSize: 10 }} />
                </View>
                <View style={{ borderBottomWidth: 1, flex: 1 }}>
                  <Text style={{ ...styles.label, fontWeight: "bold" }}>SUBSCRIBER ID #</Text>
                  <Text style={{ fontSize: 10 }} />
                </View>
                <View style={{ borderBottomWidth: 1, flex: 1 }}>
                  <Text style={{ ...styles.label, fontWeight: "bold" }}>MEDICAL #</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.label, fontWeight: "bold" }}>MEDICARE #</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ ...styles.hr, height: 200 }} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: 11,
                fontWeight: "bold",
              }}
            >
              ICD-10 CODES (REQUIRED)
            </Text>
            <View style={{ flexDirection: "row", flex: 0.7, borderBottomWidth: 1 }}>
              <View style={{ width: "33%", textAlign: "center", borderRight: "1px solid black" }}>
                <Text></Text>
              </View>
              <View style={{ width: "33%", textAlign: "center", borderRight: "1px solid black" }}>
                <Text></Text>
              </View>
              <View style={{ width: "33%", textAlign: "center" }}>
                <Text></Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", flex: 0.7, borderBottomWidth: 1 }}>
              <View style={{ width: "33%", textAlign: "center", borderRight: "1px solid black" }}>
                <Text></Text>
              </View>
              <View style={{ width: "33%", textAlign: "center", borderRight: "1px solid black" }}>
                <Text></Text>
              </View>
              <View style={{ width: "33%", textAlign: "center" }}>
                <Text></Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", flex: 0.7, borderBottomWidth: 1 }}>
              <View style={{ flex: 1, textAlign: "center" }}>
                <Text style={styles.label}>DATE OF BIRTH</Text>
                <Text style={{ fontSize: 10 }}>{formatDateOfBirthDOB(demographics?.dob)}</Text>
              </View>
              <View style={{ flex: 0.5, textAlign: "center", borderLeftWidth: 1 }}>
                <Text style={styles.label}>SEX</Text>
                <Text style={{ fontSize: 10 }}>{demographics?.sex}</Text>
              </View>
              <View style={{ flex: 1, textAlign: "center", borderLeftWidth: 1 }}>
                <Text style={styles.label}>DATE COLLECTED</Text>
                <Text style={{ fontSize: 10 }}>{formatDateMDY(test.createdAt)}</Text>
              </View>
              <View style={{ flex: 1, textAlign: "left", borderLeftWidth: 1 }}>
                <Text style={styles.label}>TIME COLLECTED</Text>
                <View
                  style={{ flexDirection: "row", textAlign: "right", alignItems: "center", justifyContent: "center" }}
                >
                  <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                    {getAMPM(test?.createdAt) === "AM" && (
                      <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                    )}
                  </View>
                  <Text style={{ ...styles.label, paddingLeft: "3px" }}>AM</Text>
                </View>
                <View
                  style={{ flexDirection: "row", textAlign: "right", alignItems: "center", justifyContent: "center" }}
                >
                  <View style={{ borderWidth: 1, height: 8, width: 8, marginTop: "2px" }}>
                    {getAMPM(test?.createdAt) === "PM" && (
                      <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                    )}
                  </View>
                  <Text style={{ ...styles.label, paddingLeft: "3px" }}>PM</Text>
                </View>
              </View>
            </View>
            <View style={{ flex: 2 }}>
              <View style={{ flex: 1, borderBottomWidth: 1, flexDirection: "row" }}>
                <View style={{ flex: 2 }}>
                  <Text style={styles.label}>PATIENT'S PHONE#</Text>
                  <Text style={{ fontSize: 10 }}>{formatPhone(test.phoneNumber)}</Text>
                </View>
                <View style={{ flex: 1, borderLeftWidth: 1 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      padding: "2px",
                    }}
                  >
                    <View style={{ borderWidth: 1, height: 8, width: 12 }} />
                    <Text style={{ ...styles.label, paddingLeft: "3px" }}>TRAVEL</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      padding: "2px",
                    }}
                  >
                    <View style={{ borderWidth: 1, height: 8, width: 12 }} />
                    <Text style={{ ...styles.label, paddingLeft: "3px" }}>VENIPUNCTURE</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, borderBottomWidth: 1, flexDirection: "row" }}>
                <View style={{ flex: 3 }} />
                <Text style={{ ...styles.label, borderLeftWidth: 1, padding: "0 2px" }}>COLLECTED BY</Text>
              </View>
              <View style={{ flex: 1, borderBottomWidth: 1, flexDirection: "row" }}>
                <View style={{ flex: 2 }} />
                <View style={{ flex: 1, borderWidth: 1, borderLeftWidth: 2 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                      rowGap: "10px",
                      padding: "2px",
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 2,
                        height: 10,
                        width: 14,
                        fontWeight: "bold",
                        paddingHorizontal: 2,
                      }}
                    />
                    <Text style={{ ...styles.label, paddingLeft: "3px" }}>STAT RESULTS</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
        </View>

        <View style={{ flex: 3, borderWidth: 2, borderColor: "black" }}>
          <Text
            style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 10, padding: "5px 0px" }}
          ></Text>
          <View style={{ flexDirection: "row", flex: 2 }}>
            <View style={{ flex: 1, paddingHorizontal: 20 }}>
              {/* {labInfo?.referredDoctor?.name && (
                <>
                  <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ borderWidth: 3, height: 16, width: 20 }} />
                    <Text style={{ fontSize: 11, marginLeft: 2 }}>Referred By: {labInfo?.referredDoctor?.name} </Text>
                  </View>
                </>
              )} */}
              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ borderWidth: 2, height: 16, width: 20 }}>
                  <Image src={tickIcon} style={{ height: 12, width: 12, marginHorizontal: 2, textAlign: "center" }} />
                </View>
                <Text style={{ fontSize: 12, marginLeft: 2, fontWeight: "bold", paddingLeft: "5px" }}>
                  7060 - COVID BY PCR SWAB
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginLeft: 30, marginTop: 5 }}>
                <View style={{ borderWidth: 2, height: 16, width: 20 }}>
                  {(demographics?.swabtype?.toLowerCase() === SYMPTOMS.nasal || !demographics?.swabtype) && (
                    <Image src={tickIcon} style={{ height: 12, width: 12, marginHorizontal: 2, textAlign: "center" }} />
                  )}
                </View>
                <Text style={{ fontSize: 12, marginRight: 30, fontWeight: "bold", paddingLeft: "5px" }}>NASAL</Text>
                <View style={{ borderWidth: 2, height: 16, width: 20 }}>
                  {demographics?.swabtype?.toLowerCase() === SYMPTOMS.oral && (
                    <Image src={tickIcon} style={{ height: 12, width: 12, marginHorizontal: 2, textAlign: "center" }} />
                  )}
                </View>
                <Text style={{ fontSize: 12, marginRight: 30, fontWeight: "bold", paddingLeft: "5px" }}>ORAL</Text>
              </View>
              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />

              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
              <Text style={{ fontSize: 7, lineHeight: 1, fontWeight: "bold" }}>
                *NEGATIVE results do not rule out SARS-CoV-2 infection, patricularly in those who have been in contact
                with the virus. Follow-up testing with a molecular diagnostic should be considered to rule out infection
                in these individuals.
              </Text>
              {/* <Text style={{ fontSize: 7, lineHeight: 2, fontWeight: "bold" }}>
                *Results from antibody testing should not be used as the solebasis to diagnose or exclude SARS-CoV-2
                infection or inform infection status.
              </Text> */}
              <Text style={{ fontSize: 7, lineHeight: 1, fontWeight: "bold" }}>
                *Positive results may be due to past or present infection with non-SARS-CoV-2 corona virus strains, such
                as HKU1, NL63, OC43, or 229E.
              </Text>
              <View style={{ alignSelf: "flex-end" }}>
                <Text style={{ fontSize: 8, marginTop: 5, textAlign: "center", fontWeight: "bold" }}>
                  *This test is FDA EUA cleared, but not FDA approved.*
                </Text>
              </View>
              <View style={{ flex:2, marginTop: 3, marginBottom:8,   borderWidth: 2,  paddingLeft: 5, paddingRight: 5 }}>
                
                  <Text style={styles.label}>Clinical Note:</Text>
                  <Text style={{ textAlign: "left", fontSize: 8, marginTop: 3, marginBottom: 3 }}>{demographics?.clinicalcomment  ? demographics?.clinicalcomment : ''}</Text>
                
              </View>
            </View>
            <View style={{ flex: 1, paddingHorizontal: 20, paddingVertical: 10 }}>
              {/* <Text style={{ fontSize: 6, lineHeight: 2 }}>
                *PLEASE NOTE: Standing orders will expire 12 months from the date issued unless otherwise specified on
                the requistion.*
              </Text> */}
              <View style={{ flex: 0.3, flexDirection: "row", borderWidth: 2 }}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.label}>PASSPORT # (IF APPLICABLE)</Text>
                  <Text
                    style={{ textAlign: "right", fontSize: 10, paddingHorizontal: 5 }}
                  >{`${formatIDNumber()}`}</Text>
                </View>
              </View>
              <View style={{ flex: 3, borderWidth: 2, marginTop: 10 }}>
                <Text style={{ textAlign: "center", fontSize: 9, borderBottomWidth: 2, fontWeight: "bold" }}>
                  HAVING SYMPTOMS? (PLEASE MARK ALL THAT APPLY)
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.label, padding: "3px 1px" }}>ARE YOU AN ESSENTIAL WORKER?</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      padding: "0 5px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                        {demographics?.worker?.toLowerCase() === SYMPTOMS.yes && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ fontSize: 10, paddingLeft: "5px" }}>YES</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                        {demographics?.worker?.toLowerCase() === SYMPTOMS.no && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ fontSize: 10, paddingLeft: "5px" }}>NO</Text>
                    </View>
                  </View>
                </View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ ...styles.label, padding: "3px 1px" }}>POSSIBLE EXPOSURE?</Text>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "0 5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "right",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                          {demographics?.possibleexposure?.toLowerCase() === SYMPTOMS.yes && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 10, paddingLeft: "5px" }}>YES</Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "right",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                          {demographics?.possibleexposure?.toLowerCase() === SYMPTOMS.no && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 10, paddingLeft: "5px" }}>NO</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ ...styles.label, padding: "3px 1px" }}>CONFIRMED EXPOSURE?</Text>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "right",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                          {demographics?.confirmedexposure?.toLowerCase() === SYMPTOMS.yes && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 10, paddingLeft: "5px" }}>YES</Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "right",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <View style={{ borderWidth: 1, height: 8, width: 8 }}>
                          {demographics?.confirmedexposure?.toLowerCase() === SYMPTOMS.no && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 10, paddingLeft: "5px" }}>NO</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ flex: 4 }}>
                  <Text style={{ ...styles.label, padding: "5px 1px" }}>PLEASE MARK ALL THAT APPLY:</Text>
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <View style={{ flex: 1, padding: "0px 5px", textAlign: "center" }}>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.fever) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>FEVER / CHILLS</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.cough) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>COUGH</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.headache) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>HEADACHE</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.fatigue) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>FATIGUE</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.soreThroat) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>SORE THROAT</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.diarrhea) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>DIARRHEA</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.other) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>OTHER:</Text>
                      </View>
                    </View>
                    <View style={{ flex: 2, padding: "0px 5px", textAlign: "center" }}>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.shortnessOfBreath) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>SHORTNESS OF BREATH</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.muscelBodyAches) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>MUSCLE / BODY ACHES</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.lossOfTaste) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>NEW LOSS OF TASTE / SMELL</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.congestion) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>CONGESTION / RUNNY NOSE</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.nausea) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>NAUSEA / VOMITING</Text>
                      </View>
                      <View style={styles.billingList}>
                        <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                          {demographics?.symptoms?.includes(SYMPTOMS.noSymptoms) && (
                            <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                          )}
                        </View>
                        <Text style={{ fontSize: 7, paddingLeft: "5px" }}>NO SYMPTOMS / ASYMPTOMATIC</Text>
                      </View>
                      <View style={{ marginTop: "10px" }}>
                        <View style={{ marginBottom: "15px", borderBottom: "1px solid black", width: "100%" }}>
                          {demographics?.othercomment && (
                            <Text style={{ fontSize: 7, paddingLeft: "5px" }}>{demographics?.othercomment}</Text>
                          )}
                        </View>
                        {/* <View style={{ marginBottom: "10px", borderBottom: "1px solid black", width: "100%" }} /> */}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flex: 0.3, flexDirection: "row", paddingHorizontal: 40, alignItems: "center" }}>
            <Text style={{ fontSize: 14, fontWeight: "bold" }}>EMAIL:</Text>
            <View
              style={{
                flex: 1,
                borderWidth: 1,
                height: 31,
                marginHorizontal: 10,
                borderBottom: 0,
                marginTop: 10,
              }}
            >
              <Text style={{ textAlign: "letf", padding: "5px", fontSize: 12 }}>{`${test?.email || ""}`}</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              marginHorizontal: 10,
              marginBottom: 20,
              flex: 1,
              justifyContent: "space-evenly",
            }}
          >
            <Text style={{ fontSize: 6, padding: "0 3px", fontWeight: "bold" }}>
              IF THE INSURANCE DOES NOT PAY IN FULL, I AGREE TO BE RESPONSIBLE FOR THE PAYMENT. I AUTHORIZE THE RELEASE
              OF MEDICAL INFORMATION RELATED TO THE SERVICE DESCRIBED HEREIN AND AUTHORIZE PAYMENT TO BE DIRECTED TO
              {labInfo?.name || "BIOLOGICAL LABORATORY, INC"}.
            </Text>
            <Text style={{ fontSize: 6, padding: "0 3px", fontWeight: "bold" }}>
              BY SIGNING BELOW, I HEREBY ACKNOWLEDGE THAT I HAVE COMPLETELY READ AND FULLY UNDERSTAND ALL THE
              INFORMATION CONTAINED ON THIS FORM.
            </Text>
            <View style={{ flexDirection: "row", borderTopWidth: 1 }}>
              <Text style={{ flex: 1, fontSize: 14, fontWeight: "bold" }}>**PATIENT SIGNATURE:</Text>
              <Text style={{ flex: 1, fontSize: 14, fontWeight: "bold" }}>**DATE: </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {signature ? (
                  <Image
                    style={{
                      height: 40,
                      textAlign: "center",
                      borderBottomWidth: 2,
                      marginHorizontal: 10,
                    }}
                    source={{
                      uri: signature,
                      headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
                    }}
                  />
                ) : (
                  <Text
                    style={{
                      borderBottomWidth: 2,
                      marginHorizontal: 10,
                    }}
                  ></Text>
                )}
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{ flex: 1, fontSize: 14, textAlign: "center", borderBottomWidth: 2, marginHorizontal: 10 }}
                >
                  {`${new Date(test.createdAt).toDateString()} ${new Date(test.createdAt).toLocaleString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}`}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontFamily: "Arial", // Use the Arial font for the entire document
  },
  infoText: {
    fontSize: 12,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  hr: {
    borderLeftColor: "black",
    borderLeftWidth: 1,
    // width: "100%",
  },
  address: {
    marginLeft: 50,
    marginVertical: 20,
  },
  label: {
    fontSize: 8,
    fontWeight: "bold",
  },
  billingList: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default BioLabRequisitionPDF;
