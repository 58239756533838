import React from "react";

const StatsView = (props) => {
  const { label, value, type, handleClick } = props;

  return (
    <div className="preRegistrationStat">
      <div>{label}</div>
      <div>
        <a
          style={{ color: "#42cef5" }}
          onClick={() => handleClick(type)}
          onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        >
          {value ?? 0}
        </a>
      </div>
    </div>
  );
};

export default StatsView;
