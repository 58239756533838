import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

const PGPagination = (props) => {
  const {
    currentPage,
    totalPages,
    handleChangePage,
    totalSelected,
    showSelectedRecord,
    timeFilter,
    totalRecord,
    currentRecord,
  } = props;

  const showDateFilter = () => {
    if (!timeFilter) return "";
    if (timeFilter && Object.keys(timeFilter).length > 0) {
      const { start, end } = timeFilter;
      const startDate = moment(start.format("YYYY-MM-DD")).startOf("day");
      const endDate = moment(end.format("YYYY-MM-DD")).endOf("day");
      return `Date Filter: ${startDate.format("MM-DD-YYYY")} - ${endDate.format("MM-DD-YYYY")}`;
    }
    return "Date Filter: All";
  };

  return (
    <Row className="pull-right mt-0" style={{ borderWidth: 1 }}>
      <Col md="5" className="selectedTestsText">
        <span>{showSelectedRecord && `Selected Test : ${totalSelected}`}</span>
        <span className="datefilter-label" style={{ fontWeight: "normal" }}>
          {showDateFilter()}
        </span>
      </Col>
      <Col className="text-right paginationText-alignment" md={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
        Page
        <ul className="createButtons pr-0" style={{ display: "inline" }}>
          <i
            className="fas fa-angle-left"
            style={{ color: "#585858" }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "black";
            }}
            onMouseLeave={(e) => (e.target.style.color = "#585858")}
            onClick={() => {
              currentPage !== 1 && handleChangePage(currentPage - 1, "prev");
            }}
          />
          {currentPage} of {totalPages} {currentRecord < totalRecord && "..."}
          <i
            className="fas fa-angle-right"
            style={{ color: "#585858" }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "black";
            }}
            onMouseLeave={(e) => (e.target.style.color = "#585858")}
            onClick={() => {
              currentPage !== totalPages && handleChangePage(currentPage + 1, "next");
            }}
          />
        </ul>
      </Col>
    </Row>
  );
};

export default PGPagination;
