import React, { useState, useContext } from "react";

// react-bootstrap components
import { Card, InputGroup, FormControl } from "react-bootstrap";
import Select from "react-select";
import { RESULT_SENT_OPTIONS, GENDER_OPTIONS, TEST_RESULT_OPTIONS, FILTER_SHOW_STATUS } from "constant";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { TEST_TYPES, VACCINATED_OPTIONS, CAPACITY_OPTIONS } from "constant";
import SingleDatePicker from "Date/SingleDatePicker";
import { TRANSITION_STATUS_OPT } from "constant";
import { stringConstant } from "constant";
import { BATCH_OPTIONS } from "constant";

const Filter = ({
  filterTerms,
  setFilter,
  filter,
  isUsersTable,
  triggerFilter,
  setTriggerFilter,
  handleClearFilter,
}) => {
  const [clearFilter, setClearFilter] = useState(false);

  const filterAlias = (term) => {
    switch (term) {
      case "Crew ID":
        return "id";
      case "Name":
        return "name";
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "FirstName":
        return "first";
      case "LastName":
        return "last";
      case "Phone":
        if (isUsersTable) {
          return "phone_number";
        } else {
          return "phoneNumber";
        }
      // return "phoneNumber";
      case "Email":
        return "email";
      case "Accession No":
      case "Barcode":
        return "barcode";
      case "Status":
        return "status";
      case "Result":
        return "result";
      // case "Lab":
      //   return "lab";
      case "Role":
        return "userRole";
      case "Contact Name":
      case "Primary Contact":
        return "contact_name";
      case "Contact Phone":
        return "contact_phone";
      case "Contact Email":
        return "contact_email";
      case "Test Type":
        return "test_type";
      case "Associated":
        return "associated";
      case "Tester":
        return "tester_name";
      case "Crew Id":
        return "employeeID";
      case "Quarantined":
        return "isQuarantined";
      case `${stringConstant.sequenceNo}`:
        return "sequenceNo";
      case "ReferenceID":
        return "referenceID";
      case "Result Sent":
        return "emailSend";
      case "Reason":
        return "reason";
      case "Insurance Info":
        return "insurance_required";
      case "Insurance Card":
        return "sendInsuranceCard";
      case "Vaccination Card":
        return "vaccinationCard";
      case "Vaccination Admit":
        return "admit";
      case "External Test":
        return "externalTest";
      case "External Test Admit":
        return "externalTestAdmit";
      case "Pattern Testing":
        return "patternTesting";
      case "Pre-Registration":
        return "preRegistration";
      case "HR Registration":
        return "isHRPR";
      case "Automate Rapid Test":
        return "antigen";
      case "Print Labels":
        return "tubes_provided";
      case "Antigen":
        return "default_antigen";
      case "Molecular":
        return "default_molecular";
      case "Accula":
        return "default_other";
      case "Send Insurance":
        return "sendInsurance";
      case "BarCodes":
        return "barCodeProvided";
      case "Result Type":
        return "resultType";
      case "White Label":
        return "whiteLabel";
      case "Gender":
        return "sex";
      case "ID Number":
        return "id_number";
      case "City":
        return "city";
      case "Vaccinated":
        return "isVaccinated";
      case "False Positive":
        return "isFalsePositive";
      case "Reference ID":
        return "referenceID";
      case "Shipment":
        return "enableShipment";
      case "Street":
        return "street";
      case "Send Quantity":
        return "testSendQty";
      case "Vaccination Type":
        return "vaccinationType";
      case "Status Step":
        return "step";
      case "Date of Birth":
        return "dob";
      case "Order Status":
        return "orderStatus";
      case "Capcity":
        return "capcity";
      case "Show Status":
        return "status";
      case "isVaccinated":
        return "vaccinated";
      case "White Glove":
        return "whiteGlove";
      case "Pattern":
        return "pattern";
      case "Capacity":
        return "capacity";
      case "Transition Status":
        return "transitionStatus";
      case "Date Created":
        return "startDate";
      case "Date End":
        return "endDate";
      case "Submitted to H&S":
        return "batch";
      case `${stringConstant.show}`:
        return "Show";
      default:
        return term;
    }
  };

  const lowerCaseKeys = (obj) => {
    const keys = Object.keys(obj);
    const newObj = {};
    for (const key of keys) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };

  const dateRangeMultiSelection = (filterTerm) => (
    <DateRangePicker
      onApply={(event, picker) => {
        picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: { startDate: picker.startDate, endDate: picker.endDate },
        });
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      initialSettings={
        filter[filterAlias(filterTerm)]
          ? filter[filterAlias(filterTerm)]
          : {
              drops: "auto",
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
              },
            }
      }
    >
      <input type="text" placeholder="Select Date Range" readOnly className="dateRangeFilter" defaultValue="" />
    </DateRangePicker>
  );

  const dateRangeSelection = (filterTerm) => (
    <SingleDatePicker
      onApply={(event, picker) => {
        const date = moment(picker.startDate);
        if (date.isValid()) {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: date.format("MM/DD/YYYY"),
          });
        }
      }}
      value={filter["dob"]}
      currentDate={true}
    />
  );

  const getDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData?.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="result-filter-width filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        value={prevSelectedVal && prevSelectedVal.length > 0 ? prevSelectedVal[0] : null}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.value,
          });
        }}
      />
    );
  };

  const getMultiDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="result-filter-width filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        isMulti
        value={prevSelectedVal}
        onChange={(e) => {
          console.log("E", e);
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t.value),
          });
        }}
      />
    );
  };

  const filterContent = (filterTerm) => {
    switch (filterTerm) {
      case "Result Sent":
        return getDropDown(RESULT_SENT_OPTIONS, filterTerm);
      case "Submitted to H&S":
        return getDropDown(BATCH_OPTIONS, filterTerm);
      case "Gender":
        return getDropDown(GENDER_OPTIONS, filterTerm);
      case "Date of Birth":
        return dateRangeSelection(filterTerm);
      case "Test Type":
        return getMultiDropDown(TEST_TYPES, filterTerm);
      case "Show Status":
        return getDropDown(FILTER_SHOW_STATUS, filterTerm);
      case "isVaccinated":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "White Glove":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Pattern":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Capacity":
        return getDropDown(CAPACITY_OPTIONS, filterTerm);
      case "Transition Status":
        return getDropDown(TRANSITION_STATUS_OPT, filterTerm);
      case "Date Created":
        return dateRangeMultiSelection(filterTerm);
      case "Date End":
        return dateRangeMultiSelection(filterTerm);
      // case "Result":
      //   return getMultiDropDown(TEST_RESULT_OPTIONS, filterTerm);
      default:
        return (
          <FormControl
            onChange={(e) => {
              setFilter({
                ...filter,
                [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
              });
            }}
            placeholder={`Enter ${filterTerm}`}
            value={filter[filterAlias(filterTerm)]}
          />

          // <FormControl
          //   onChange={(e) => {
          //     setFilter({
          //       ...filter,
          //       [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
          //     });
          //   }}
          //   value={filter[filterAlias(filterTerm)]}
          // />
        );
    }
  };

  const renderFilters = (filters) => {
    return filters.map((filterTerm) => {
      return (
        <InputGroup key={filterTerm}>
          {triggerFilter && (setFilter({ ...filter }), setTriggerFilter(false))}
          <InputGroup.Text
            style={{
              width: "175px",
            }}
          >
            {filterTerm}
          </InputGroup.Text>
          {filterContent(filterTerm)}
        </InputGroup>
      );
    });
  };

  return (
    <div>
      <div className="filterResetBtn">
        <span
          onClick={() => {
            Array.from(document.querySelectorAll("input")).forEach((input) => (input.value = ""));
            setFilter({});
            if (handleClearFilter) handleClearFilter();
          }}
          className="linkedText textUnderLine"
        >
          Clear Filter
        </span>
      </div>
      <div className="mt-t custom-filters-wrapper">{renderFilters(filterTerms)}</div>
    </div>
  );
};

export default Filter;
