import React from "react";
import RadioButton from "../RadioButton/RadioButton";

const RadioInput = (props) => {
  const { options, label, input, handleRadioButtonChange, disabled } = props;
  return (
    <div className="form-group-wrapper mt-3">
      <label className="status">{label}</label>
      <div className="d-flex column-gap-50 align-items-center">
        {options.map((m) => (
          <RadioButton
            id={m.id}
            label={m.label}
            disabled={disabled}
            name={m.name}
            checked={input === m.value}
            value={m.value}
            handleRadioButtonChange={handleRadioButtonChange}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
