/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLab = /* GraphQL */ `
  subscription OnCreateLab {
    onCreateLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateLab = /* GraphQL */ `
  subscription OnUpdateLab {
    onUpdateLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteLab = /* GraphQL */ `
  subscription OnDeleteLab {
    onDeleteLab {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      fax_number
      user_id
      contact_email
      tubes_provided
      lab_director
      referredDoctor
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      custom_notes
      invalid_notes
      indeterminate_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      logourl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite {
    onCreateSite {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite {
    onUpdateSite {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite {
    onDeleteSite {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      companyID
      sendIdentification
      sendExternalRequisition
      sendPrescription
      sendAuthorization
      sendOtherDocuments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient {
    onCreateClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      pgName
      colLabId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient {
    onUpdateClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      pgName
      colLabId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient {
    onDeleteClient {
      id
      name
      contact_name
      contact_phone
      phone_number
      fax_number
      street
      city
      state
      zipcode
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      resultTypes
      pgName
      colLabId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          companyID
          sendIdentification
          sendExternalRequisition
          sendPrescription
          sendAuthorization
          sendOtherDocuments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          county
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          sendSMS
          sendEmail
          totalTest
          connectedTo
          createdBy
          createdByName
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee {
    onCreateEmployee {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee {
    onUpdateEmployee {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee {
    onDeleteEmployee {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      county
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      sendSMS
      sendEmail
      totalTest
      connectedTo
      createdBy
      createdByName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          isExternalTest
          externalTestFile
          testLocation
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateTest = /* GraphQL */ `
  subscription OnCreateTest {
    onCreateTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateTest = /* GraphQL */ `
  subscription OnUpdateTest {
    onUpdateTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteTest = /* GraphQL */ `
  subscription OnDeleteTest {
    onDeleteTest {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      isExternalTest
      externalTestFile
      testLocation
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        fax_number
        user_id
        contact_email
        tubes_provided
        lab_director
        referredDoctor
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        custom_notes
        invalid_notes
        indeterminate_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        logourl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        fax_number
        street
        city
        state
        zipcode
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        resultTypes
        pgName
        colLabId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateLencoBarcodes = /* GraphQL */ `
  subscription OnCreateLencoBarcodes {
    onCreateLencoBarcodes {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLencoBarcodes = /* GraphQL */ `
  subscription OnUpdateLencoBarcodes {
    onUpdateLencoBarcodes {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLencoBarcodes = /* GraphQL */ `
  subscription OnDeleteLencoBarcodes {
    onDeleteLencoBarcodes {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTestConsent = /* GraphQL */ `
  subscription OnCreateTestConsent {
    onCreateTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateTestConsent = /* GraphQL */ `
  subscription OnUpdateTestConsent {
    onUpdateTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteTestConsent = /* GraphQL */ `
  subscription OnDeleteTestConsent {
    onDeleteTestConsent {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        companyID
        sendIdentification
        sendExternalRequisition
        sendPrescription
        sendAuthorization
        sendOtherDocuments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        county
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        sendSMS
        sendEmail
        totalTest
        connectedTo
        createdBy
        createdByName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          fax_number
          street
          city
          state
          zipcode
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          resultTypes
          pgName
          colLabId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateTestTypes = /* GraphQL */ `
  subscription OnCreateTestTypes {
    onCreateTestTypes {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTestTypes = /* GraphQL */ `
  subscription OnUpdateTestTypes {
    onUpdateTestTypes {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTestTypes = /* GraphQL */ `
  subscription OnDeleteTestTypes {
    onDeleteTestTypes {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmployeeBarCodes = /* GraphQL */ `
  subscription OnCreateEmployeeBarCodes {
    onCreateEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onUpdateEmployeeBarCodes = /* GraphQL */ `
  subscription OnUpdateEmployeeBarCodes {
    onUpdateEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onDeleteEmployeeBarCodes = /* GraphQL */ `
  subscription OnDeleteEmployeeBarCodes {
    onDeleteEmployeeBarCodes {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onCreateEmployeeTestCounter = /* GraphQL */ `
  subscription OnCreateEmployeeTestCounter {
    onCreateEmployeeTestCounter {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmployeeTestCounter = /* GraphQL */ `
  subscription OnUpdateEmployeeTestCounter {
    onUpdateEmployeeTestCounter {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmployeeTestCounter = /* GraphQL */ `
  subscription OnDeleteEmployeeTestCounter {
    onDeleteEmployeeTestCounter {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExternalTest = /* GraphQL */ `
  subscription OnCreateExternalTest {
    onCreateExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onUpdateExternalTest = /* GraphQL */ `
  subscription OnUpdateExternalTest {
    onUpdateExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onDeleteExternalTest = /* GraphQL */ `
  subscription OnDeleteExternalTest {
    onDeleteExternalTest {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onCreateEmployeeLogin = /* GraphQL */ `
  subscription OnCreateEmployeeLogin {
    onCreateEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmployeeLogin = /* GraphQL */ `
  subscription OnUpdateEmployeeLogin {
    onUpdateEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmployeeLogin = /* GraphQL */ `
  subscription OnDeleteEmployeeLogin {
    onDeleteEmployeeLogin {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTestSession = /* GraphQL */ `
  subscription OnCreateTestSession {
    onCreateTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTestSession = /* GraphQL */ `
  subscription OnUpdateTestSession {
    onUpdateTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTestSession = /* GraphQL */ `
  subscription OnDeleteTestSession {
    onDeleteTestSession {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
