import React, { useState, useEffect } from "react";
import API from "api";
import { Card, Form, Container, Row, Col, Modal, Table } from "react-bootstrap";

import { getConnectedToValue } from "utils";

const RelationView = (props) => {
  const [connected, setConnected] = useState([]);
  const [expandReltionList, setExpandRelationList] = useState(true);

  const { employee } = props;

  useEffect(async () => {
    if (employee) {
      const connectedTo = getConnectedToValue(employee.connectedTo);
      console.log("connectedTo", connectedTo);

      if (connectedTo.length === 0) return;

      const employeeList = await API.employeeByIds(connectedTo?.map((item) => item.id));
      const relationList = employeeList.map((m) => {
        const findRes = connectedTo.find((f) => f.id === m.id);
        if (findRes) {
          return {
            ...m,
            relation: findRes.relation,
            manageAs: findRes.relationType === "Parent" ? "Main Contact" : "Sub Contact",
            firstName: m.first,
            lastName: m.last,
            phoneNumber: m.phone_number,
            mdID: m.id,
          };
        }
        return { ...m };
      });
      setConnected(relationList);
      console.log("employeeList", relationList);
    }

    // getConnectedProfiles(Employee?.connectedTo);
  }, [employee]);

  return (
    <Row className="mx-0">
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title
              as="h4"
              style={{
                marginBottom: 10,
                fontWeight: "bold",
                color: "#565656",
              }}
            >
              Proxy Contacts ({connected.length})
            </Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width px-0 desktop-noScroll">
           
            <div>
              {expandReltionList && (
                <Card>
                  <Card.Body className="table-full-width px-0 desktop-noScroll">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="tdWidth12">First Name</th>
                          <th className="tdWidth12">Last Name</th>
                          <th className="tdWidth12">Email</th>
                          <th className="tdWidth12">Relation</th>
                          <th className="tdWidth12">Manage As A</th>
                        </tr>
                      </thead>
                      <tbody>
                        {connected?.map((item, i) => {
                          return (
                            <tr>
                              <td>{item.firstName}</td>
                              <td>{item.lastName}</td>
                              <td>{item.email}</td>
                              <td>{item.relation}</td>
                              <td>{item.manageAs}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RelationView;
