import React, { useState, useRef } from "react";
import { Storage } from "aws-amplify";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import SweetAlert from "react-bootstrap-sweetalert";
import { Bounce } from "react-activity";
import "../../src/tableStyling.css";
import Loader from "../components/Loader/Loader";

const Hipaa = (props) => {
  const { openHipaa, handleClose, client, resetClients } = props;
  console.log("client in open Hippa ::::", client);
  console.log("openHipaa ::::", openHipaa);
  const [consentForm, setConsentForm] = useState("");
  // const [clients, setClients] = useState([]);
  const [toUpdate, setToUpdate] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [savable, setSavable] = useState(false);
  const [isEdited, setEdited] = useState(true);
  const [originalForm, setOriginalForm] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [error, setError] = useState("");
  const [showFileUpload, setShowFileUpload] = useState(client.hippaFile ? true : false);
  const [fileName, setFileName] = useState(client.hippaFile || "");
  const [filePath, setFilePath] = useState("");
  const [loading, setLoading] = useState(false);
  const inputFile = useRef();

  const getFileFromStorage = async (file) => {
    const path = await Storage.get(file);
    setLoading(false);
    setFilePath(path);
  };

  useEffect(() => {
    const file = client.hippaFile || "";
    setShowFileUpload(file ? true : false);
    setFileName(file);
    getFileFromStorage(file);
  }, [client]);

  useEffect(() => {
    // fetchClients();
    setConsentForm(client.hippa);
    setToUpdate({ id: client.id });
    setOriginalForm(client.hippa);
    // return setConsentForm('');
  }, [openHipaa]);

  useEffect(() => {
    onEditForm();
  }, [consentForm]);

  useEffect(() => {
    setSavable(Object.keys(toUpdate).length > 0);
  }, [consentForm]);

  // not in use anymore? remove next release after confirmation
  // const fetchClients = async () => {
  //   const models = await API.getClients();
  //   setClients(models);
  // };

  const updateClient = async () => {
    try {
      if (consentForm.length > 7000) {
        setError("Text size is too large please upload as pdf");
        setConsentForm("");
        setShowFileUpload(true);
        return;
      }

      await API.updateClientsHippa(toUpdate.id, consentForm, fileName);
      resetClients();
      setShowAlert(true);
    } catch (error) {
      console.log(error);
      alert("Failed to update");
    }
  };

  const onEditForm = () => {
    if (consentForm == originalForm) {
      setEdited(false);
    } else {
      setEdited(true);
    }
  };

  const onChangeForm = (e) => {
    setConsentForm(e.target.value);
    onEditForm();
  };

  const hideAlert = () => {
    setToUpdate({});
    setConsentForm("");
    setShowAlert(false);
    handleClose();
  };

  const onChangeFile = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      setEdited(true);
      setLoading(true);
      const file = event.currentTarget.files[0];
      const fname = `${client.id}-hippa.pdf`;
      const response = await Storage.put(fname, file);
      setConsentForm("");
      setFileName(fname);
      getFileFromStorage(fname);
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setEdited(true);
  };

  const handleUploadClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <>
      <Modal
        show={openHipaa}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        fullscreen={fullscreen}
        onHide={() => {
          handleClose();
        }}
        dialogClassName="hipaaDialogue"
      >
        <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
          HIPAA guidelines successfully updated!
        </SweetAlert>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            HIPAA Guidelines and Consent Form
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="hipaaBody">
          {/* <Row>
            <h4>HIPAA Guidelines and Consent Form</h4>
          </Row> */}
          {/* <Row style={{ marginTop: 15, marginBottom: 15 }}> */}
          {/* <DropdownButton
              title={toUpdate.name ? toUpdate.name : "Choose client to update"}
            > */}
          {/* {clients.map((client) => (
                <Dropdown.Item
                  key={client.id}
                  onClick={() => {
                    setToUpdate({ id: client.id, name: client.name });
                    setConsentForm(client.hippa);
                  }}
                >
                  {client.name}
                </Dropdown.Item>
              ))}
            </DropdownButton> */}
          {/* </Row> */}

          <textarea value={consentForm} onChange={onChangeForm} className="hipaaTextArea" />
          {showFileUpload && (
            <div className="d-flex justify-content-start">
              <input
                ref={inputFile}
                type="file"
                accept="application/pdf"
                onChange={(e) => onChangeFile(e)}
                style={{ display: "none" }}
              />
              <Button onClick={handleUploadClick} size="sm" className="modalButtons uploadFileHippa" variant="primary">
                Upload File
              </Button>
              {fileName && (
                <>
                  <div className="d-flex align-items-center">
                    <span className="fileNameHippa">{fileName}</span>
                    <div
                      className="btn-sm btn btn-outline-danger"
                      style={{ margin: "0 15px" }}
                      onClick={handleRemoveFile}
                    >
                      Remove
                    </div>
                  </div>
                  <a href={filePath} download className="btn btn-sm btn-outline-primary">
                    Download
                  </a>
                </>
              )}
            </div>
          )}
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Row className="gap-3">
            <Button onClick={() => handleClose()} className="modalButtons" variant="secondary">
              Close
            </Button>
            {savable && (
              <Button onClick={() => updateClient()} className="modalButtons" disabled={!isEdited} variant="primary">
                Save
              </Button>
            )}
          </Row>
        </Modal.Footer>
        {loading && <Loader />}
      </Modal>
    </>
  );
};

export default Hipaa;
