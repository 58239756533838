import React, { useState, useContext } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { useEffect } from "react";
import Select from "react-select";
import API from "api";
import { AppContext } from "../../context/app-context";
import { RESULT_SETTINGS_OPT } from "constant";
import { CONFIG } from "constant";
import { async } from "@firebase/util";
import api from "api";

const TestResultSetting = (props) => {
  const appContext = useContext(AppContext);
  const [resultSettings, setResultSettings] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const onHandleSave = async () => {
    console.log("resultSettings", resultSettings);
    const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
    if (client) {
      const results = resultSettings.map((m) => m.value);
      const obj = { ...client };
      Object.assign(obj, { resultTypes: { results } });

      await api.updateClientResultTypes(obj);
      setIsEdit(false);
      await appContext.resetClients();
      appContext.showSuccessMessage("Update Result Types!");
    } else {
      appContext.showErrorMessage("Client Not Exist!");
    }
  };

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultSettings(client.resultTypes.results.map((m) => ({ value: m, label: m })));
      }
    }
  }, [appContext.clients]);

  return (
    <Container fluid>
      <div className="shows-screen-parent">
        <Row className="mx-0">
          <Col md="12">
            <Card className="strpied-tabled-with-hover bg-transparent">
              <Card.Header className="shows-screen-wrapper">
                <Row className="pb-4">
                  <Col md-="12">
                    <Card.Title>
                      <div className="">Result Settings</div>
                    </Card.Title>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 mt-0">
                <div className="formWrapper">
                  <div>Result Setting:</div>
                  <div className="mnw-25">
                    <Select
                      options={RESULT_SETTINGS_OPT}
                      blurInputOnSelect={true}
                      className="w-100"
                      defaultValue={null}
                      menuPlacement="auto"
                      isMulti
                      value={resultSettings}
                      isDisabled={!isEdit}
                      onChange={(e) => {
                        setResultSettings(e);
                      }}
                    />
                  </div>
                  <div>
                    {!isEdit && (
                      <Button variant="primary" className="modalButtons mx-2" onClick={() => setIsEdit(true)}>
                        Edit
                      </Button>
                    )}
                    {isEdit && (
                      <>
                        <Button variant="secondary" className="modalButtons mx-2" onClick={() => setIsEdit(false)}>
                          Cancel
                        </Button>
                        <Button variant="primary" className="modalButtons" onClick={onHandleSave}>
                          Update
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TestResultSetting;
