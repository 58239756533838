import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import api from "api";
import ImageInput from "components/Input/ImageInput";
import moment from "moment";
import { Storage } from "aws-amplify";
import { CSVReader } from "react-papaparse";
import { exportToCalRedie } from "utils";

const CalRedieLogsModal = (props) => {
  const { handleClose, user, appContext } = props;
  const [detail, setDetail] = useState({});
  const [retunStatusCard, setReturnStausCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [error, setError] = useState("");

  const handleOnDrop = (arr) => {
    let patients = [];
    for (const { data } of arr) {
      if (!data.accession_number) continue;
      patients.push(data);
    }
    if (patients.length > 0) {
      setRecords(patients);
    } 
  };

  const handleOnError = (err) => {
    console.log(err);
    setError(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
    setRecords([]);
  };

  const onHandleConfirm = async () => {
    if (!retunStatusCard) {
      setError("Please Upload an image");
      return;
    }
    if (!detail.code || !detail.code.trim()) {
      setError("Confirmation Code is Required");
      return;
    }
    if (records.length === 0) {
      setError("Please Upload Csv File !");
      return;
    }
    let imageFileName = "";

    setLoading(true);
    if (retunStatusCard instanceof Object) {
      imageFileName = await api.saveFileCalredieImg(retunStatusCard);
    }

    const results = records.map((m) => ({
      ...m,
      confirmation_code: detail.code,
      file: imageFileName,
      userName: user.name,
      userID: user.sub,
    }));

    console.log("results", results);
    try {
      await api.uploadFileCalredieCsv(results);
      appContext.showSuccessMessage(`Successfully Added Health and Safety Confrimation Code`);
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      appContext.showErrorMessage(err.message);
      handleClose();
    }
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Add Health & Safety Confirmation Code
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group-wrapper align-items-center">
          <label className="modalLineHeaders">Choose Image: </label>
          <ImageInput
            cssClass="vaccinationField profile-image"
            title=""
            setSelectedImage={setReturnStausCard}
            selectedImage={retunStatusCard}
          />
        </div>
        <div className="form-group-wrapper my-5">
          <label className="modalLineHeaders">Confirmation Code: </label>
          <input
            className="modalInput w-50"
            placeholder="Enter Confirmation Code"
            type="text"
            value={detail.code}
            onChange={(e) => setDetail({ ...detail, code: e.target.value })}
          />
        </div>
        <div className="form-group-wrapper d-block md-customer-reader">
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
              skipLinesWithEmptyValues: true, // Skip lines with empty values
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop (Export to H&S) CSV file here for upload logs.</span>
          </CSVReader>
        </div>

        {/* <div className="form-group-wrapper">
          <label className="modalLineHeaders">Message: </label>

          <textarea className="modalInput w-75" onChange={(e) => setDetail({ ...detail, message: e.target.value })}>
            {detail.message}
          </textarea>
        </div> */}
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => onHandleConfirm()}>
          Save
        </Button>
      </Modal.Footer>

      {loading && <Loader />}
    </Modal>
  );
};

export default CalRedieLogsModal;
