import React from "react";
import { Button, Modal , Table} from "react-bootstrap";

const ResultModal = (props) => {
  const { data, handleClose } = props;

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Uploaded Result Status
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Result Type</span>
                    </div>
                  </th>
                 
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span># of Results</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((msg, index) => {
                  return (
                    <tr key={index}>
                      <td>{msg.label}</td>
                      <td style={{ textAlign: "center" }}>{msg.tests}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultModal;
