/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Test from "views/Test";
import TableListApi from "views/GraphQL/TableListApi";
import ProcessedTestListApi from "views/GraphQL/ProcessedTestListApi";
import UsersViewWhiteLabel from "views/GraphQL/UsersViewWhiteLabel";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Settings from "views/Settings.js";
import LabSites from "views/GraphQL/LabSites";
import CrewProfile from "components/CrewProfile";
import ExternalTestCrew from "views/ExternelTestCrew";
import AdminTotalTestApi from "views/GraphQL/AdminTotalTestApi";
import DeleteTestApi from "views/GraphQL/DeleteTestApi";
import PendingReleaseTestListView from "views/GraphQL/PendingReleaseTestListView";
import TestListAdminApi from "views/GraphQL/TestListAdminApi";
import UnprocessedTestsApi from "views/GraphQL/UnprocessedTestsApi";
import UsersView from "views/GraphQL/UsersView";
import ProfileEmployeeTest from "views/ProfileEmployeeTest";
import TestTypesSetting from "views/TestTypeSetting/TestTypesSetting";
import TestDetails from "views/TestDetails";
import Employee from "views/Hipaa/Employees";

const dashboardRoutes = [
  {
    path: "/employees",
    name: "Employees",
    icon: "nc-icon nc-notes",
    component: Employee,
    layout: "/admin",
  },
  // {
  //   path: "/unprocessedtests",
  //   name: "Unprocessed Tests",
  //   icon: "nc-icon nc-notes",
  //   component: UnprocessedTestsApi,
  //   layout: "/admin",
  // },
  {
    path: "/senttest",
    name: "Waiting Results",
    icon: "nc-icon nc-notes",
    component: UnprocessedTestsApi,
    layout: "/admin",
  },
  {
    path: "/alltests",
    name: "Completed Tests",
    icon: "nc-icon nc-check-2",
    component: TestListAdminApi,
    layout: "/admin",
  },
  // {
  //   path: "/totaltests",
  //   name: "All Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: AdminTotalTestApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/expiredtests",
  //   name: "Expired Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: ExpiredTestApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/deletedtests",
  //   name: "Deleted Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: DeleteTestApi,
  //   layout: "/admin",
  // },
  /*  {
    path: "/emailstatus",
    name: "Email Status",
    icon: "nc-icon nc-check-2",
    component: EmailStatusTestList,
    layout: "/admin",
  }, */
  // {
  //   path: "/externaltests",
  //   name: "External / Vacc Admit",
  //   icon: "nc-icon nc-circle-09",
  //   component: ExternalTest,
  //   layout: "/admin",
  // },
  //   {
  //     path: "/preregistration",
  //     name: "Pre Registration",
  //     icon: "nc-icon nc-atom",
  //     component: PreRegistrationView,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/genericpreregistration",
  //     name: "Generic Pre-Reg",
  //     icon: "nc-icon nc-atom",
  //     component: GenericPreRegistration,
  //     layout: "/admin",
  //   },
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: TestDetails,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/employee/:employeeId",
    name: "Profile Employee Test",
    icon: "nc-icon nc-circle-09",
    component: ProfileEmployeeTest,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/externaltest/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: ExternalTestCrew,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/crewprofile/:empId",
    name: "CrewProfile",
    icon: "nc-icon nc-circle-09",
    component: CrewProfile,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/locations",
    name: "Locations",
    icon: "nc-icon nc-tv-2",
    component: LabSites,
    layout: "/admin",
  },
  // {
  //   path: "/users",
  //   name: "System User",
  //   icon: "nc-icon nc-badge",
  //   component: UsersView,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pendingrelease",
  //   name: "Pending Release",
  //   icon: "nc-icon nc-notes",
  //   component: PendingReleaseTestListView,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tests",
  //   name: "New Tests",
  //   icon: "nc-icon nc-notes",
  //   component: TableListApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/processing",
  //   name: "Tests Processing",
  //   icon: "nc-icon nc-notes",
  //   component: TableListApi,
  //   layout: "/admin",
  // },
  // {
  //   path: "/processedtests",
  //   name: "Processed Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: ProcessedTestListApi,
  //   layout: "/admin",
  // },

  {
    path: "/whiteusers",
    name: "System User",
    icon: "nc-icon nc-badge",
    component: UsersViewWhiteLabel,
    layout: "/admin",
  },
  // {
  //   path: "/testtypes",
  //   name: "Settings",
  //   icon: "nc-icon nc-notes",
  //   component: TestTypesSetting,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
