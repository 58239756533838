import API from "api";
import Filter from "components/Filter";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT, PERSONALISATION_KEY, PRE_REGISTRATION_STATUS } from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useMemo, useCallback, useState } from "react";
import { debounce } from "debounce";
import "react-activity/dist/Bounce.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  calculateTdWidth,
  changeSort,
  formatDoBForDemo,
  getPhoneNo,
  makeBarcode,
  parseBooleanValue,
  personalisationInLC,
  showsInSS,
  sortingFilterInLC,
  sortTestList,
} from "utils";
import GQAPI from "views/GraphQL/gqApi";

import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PersonalizationModal from "components/Modal/personalizationModal";
import { EMPS_DROPDOWN_OPTIONS } from "constant";
import moment from "moment";
import { TABLE_QUICK_TOOLS } from "constant";
import MainTable from "components/Table/MainTable";
import { defaultPersonalization } from "constant";
import EmployeeModal from "components/Hipaa/Modal/EmployeeModal";

const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({ isNew: true });
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  const [personalize, setPersonalize] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit];

  const STATS_CLICK_TYPE = {
    ORDERS: "orders",
    ATTENDEES: "attendess",
    COMPLETED: "completed",
    UPLOAD: "upload",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
  };

  const selectedItem = ["first", "last", "email", "phone_number"];

  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [showToDelete, setShowToDelete] = useState("");
  const [labs, setLabs] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [error, setError] = useState("");
  const [stats, setStats] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];

  const appContext = useContext(AppContext);

  const itemsToMap = useMemo(() => {
    const list = filteredEmployees;
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredEmployees, currentPage]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    console.log("Filter", filters);
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "firstName") {
          return obj["first"] && obj["first"].toLowerCase().includes(filters[key]);
        }
        if (key === "lastName") {
          return obj["last"] && obj["last"].toLowerCase().includes(filters[key]);
        }
        if (key === "Show") {
          return obj.showId && appContext.getSiteName(obj.showId).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "step") {
          return obj.step && PRE_REGISTRATION_STATUS[obj.step].toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "isVaccinated") {
          return parseBooleanValue(obj.isVaccinated).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "sex") {
          return obj[key] && obj[key] == filters[key];
        }

        if (key === "ordered") {
          return obj["testSend"] === "true" && obj["testSendQty"];
        }
        if (key === "shipped") {
          return obj["orderStatus"] && obj["orderStatus"] !== "delivered";
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    setPersonalize(personalisationInLC.get(PERSONALISATION_KEY.EMPLOYEE));
    if (sortingFilters.sites.sortBy) {
      setSortDescending(sortingFilters.sites.sortDescending);
      setSortBy(sortingFilters.sites.sortBy);
    }
  }, []);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, page, sortBy, sortDescending) => {
      loadEmployees(filter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
    setTimeout(() => setShowFilter(false), 1);
  }, [location.state]);

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, 1, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const loadEmployees = async (params, page, sortBy, sortByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page });

      if (orderBy) {
        Object.assign(filterParam, { orderBy: sortBy, orderByDirection: sortByDirection });
      }

      const pgList = await API.getHipaaEmployeeList(filterParam);
      setLoading(false);

      let ttlEmps = tests.concat(pgList.rows);
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
        ttlEmps = pgList.rows;
      }
      setAllSelected(false);
      setEmployees(ttlEmps);
      refreshList(ttlEmps);
    } catch (err) {
      setLoading(false);
    }
  };

  const refreshList = (list) => {
    if (sortBy) {
      setFilteredEmployees(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredEmployees(list);
    }
  };

  const handleChangePage = (page) => setCurrentPage(page);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalisationInLC.saveAs(data, "employee");
    personalisationInLC.save(JSON.stringify(personalisationData));
    setOpenPersonalizationModal(false);
    await API.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      const updateSelectedTestResult = filteredEmployees
        .filter((test) => checkboxes.includes(test.id))
        .map((d) => {
          return { id: d.id, phone_number: d.phone_number };
        });
      const ttlTest = updateSelectedTestResult.length;
      if (!ttlTest) {
        return;
      }
      setLoading(true);
      await API.deletePreRegistration(updateSelectedTestResult);
      setUsers(users.filter((test) => !checkboxes.includes(test.id)));
      setFilteredEmployees(filteredEmployees.filter((test) => !checkboxes.includes(test.id)));
      setCheckboxes([]);
      appContext.showSuccessMessage("Record removed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  // OPen delivery model from actions th

  return (
    <Container fluid>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      <Icon
                        handleClick={() => setOpenEmployeeModal(true)}
                        label={"Create"}
                        title={"Create"}
                        iconType={"addUserIcon"}
                      />
                      {filteredEmployees.length > 0 && (
                        <div className="headerButton">
                          {Object.keys(filter).length > 0 && (
                            <>
                              <ExportToExcel
                                items={filteredEmployees}
                                selectedItem={selectedItem}
                                appContext={appContext}
                                filter={filter}
                                title="Pre Registration User"
                                sortBy={sortBy}
                                summary={stats}
                              />
                            </>
                          )}
                        </div>
                      )}
                      <Icon
                        disabled={checkboxes.length === 0}
                        handleClick={() => setOpenDeleteConfirmation(true)}
                        title={"Delete"}
                        label={"Delete"}
                        iconType={"binIcon"}
                      />
                      <Icon
                        handleClick={() => setOpenPersonalizationModal(true)}
                        title={"Personalize"}
                        label={"Personalize"}
                        iconType={"personalizeIcon"}
                      />
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                      isUsersTable={true}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="striped-tabled-with-hover bg-transparent">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Employees:
                          <span className="ps-2"> {filteredEmployees ? filteredEmployees.length : 0}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                      />
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="px-0 l mt-0 pt-0 table-full-width desktop-noScroll">
                  <MainTable
                    columns={personalize}
                    rows={filteredEmployees}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    draggable
                    resizable
                    personalisationKey={"employee"}
                    handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                    widthToSkip={155}
                    selectedRows={checkboxes}
                    tools={quickTools}
                    dropDownOptions={EMPS_DROPDOWN_OPTIONS}
                    handleDropDownClick={(type, row) => {
                      console.log("handleDropDownClick", type);
                    }}
                    handleCellClick={(obj) => {
                      console.log("handleCellClick", obj);
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}

      {openEmployeeModal && (
        <EmployeeModal
          handleClose={() => setOpenEmployeeModal(false)}
          selectItem={selectedEmployee}
          handleSave={() => console.log("save employee")}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Record"
          message="Are you sure, you want to remove Record?"
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </Container>
  );
};

export default Employee;
