import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { formatPhoneNumber, getOrderDetail, capitalizeLetter, parseBooleanValue } from "utils";
import moment from "moment";
import { PRE_REGISTRATION_STATUS } from "constant";
import Loader from "./Loader/Loader";
import API from "api";
import Icon from "./Icon";
import { stringConstant } from "constant";
import { CONFIG } from "constant";
import { connectedIDInLocalStorage } from "utils";
import { getValidName } from "utils";

const ExportToExcelData = (props) => {
  const { items, selectedItem, title, appContext, filter, timeFilter, sortBy, summary, setLoading, profileView } =
    props;
  const [list, setList] = useState([]);

  const fetchData = () => {
    setLoading(true);
    loadData();
  };

  useEffect(() => {
    if (list.length > 0) {
      handleExportToExcel();
    } else {
      setLoading(false);
    }
  }, [list]);

  const loadData = async () => {
    try {
      const filterParam = { ...filter };
      if (CONFIG.isWhiteLabel && connectedIDInLocalStorage.get()) {
        filterParam["labID"] = connectedIDInLocalStorage.get();
      }

      if (CONFIG.isWhiteLabel && CONFIG.clientID) {
        filterParam["clientID"] = CONFIG.clientID;
      }
      if (timeFilter) {
        const { start, end } = timeFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (Object.keys(filterParam).length > 0) {
        if (profileView) {
          await API.downloadEmployeeCSV(filterParam, setList);
        } else {
          await API.downloadcsv(filterParam, setList);
        }
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  const exportData = async () => {
    if (!selectedItem) return list;

    return list.map((item) => {
      let obj = {};

      selectedItem.forEach((c) => {
        if (c === "firstName" || c === "lastName") {
          const val = getValidName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "phoneNumber" || c === "phone_number") {
          const val = formatPhoneNumber(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "accession_no") {
          Object.assign(obj, { [c]: item["barcode"] });
        } else if (c === "updatedAt" || c === "createdAt" || c === "batch" || c === "resultDate") {
          const val = item[c] ? moment(item[c]).format("MMM DD, YYYY") : moment(item.createdAt).format("MMM DD, YYYY");
          Object.assign(obj, { [c]: val });
        } else if (c === "dob") {
          if (item[c]) return Object.assign(obj, { [c]: moment(item[c]).format("MM/DD/YYYY") });
          return Object.assign(obj, { [c]: "-" });
        } else if (c === "clientID") {
          const val = appContext.getClientName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "lastUsed") {
          if (item[c]) {
            Object.assign(obj, { [c]: moment(item[c]).format("MM-DD-YYYY HH:mm") });
          } else {
            Object.assign(obj, { [c]: "-" });
          }
        } else if (c === "site_name") {
          if (item[c]) {
            Object.assign(obj, { [c]: capitalizeLetter(item[c]) });
          } else {
            Object.assign(obj, { [c]: "-" });
          }
        } else if (c === "siteID") {
        } else if (c === "patternTestAnswer") {
          const val = item[c] && item[c] !== "undefined" ? "True" : "False";
          Object.assign(obj, { [c]: val });
        } else if (c === "siteID") {
          const val = appContext.getSiteName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "labID") {
          const val = appContext.getLabName(item[c]);
          Object.assign(obj, { [c]: val });
        } else if (c === "emailSend") {
          const val = item[c] ? "✔" : "";
          Object.assign(obj, { [c]: val });
        } else if (c === "step") {
          Object.assign(obj, { [c]: PRE_REGISTRATION_STATUS[item[c]] });
        } else {
          Object.assign(obj, { [c]: item[c] });
        }
      });
      return obj;
    });
  };
  const handleExportToExcel = async () => {
    if (list?.length > 0) {
      const data = await exportData();
      const colLength = Object.keys(data[0]).length;
      const rowLength = Object.keys(data).length;

      const filters = {
        id: { data: filter.id, label: "id" },
        first: { data: filter.first, label: "First Name" },
        last: { data: filter.last, label: "Last Name" },
        firstName: { data: filter.firstName, label: "First Name" },
        lastName: { data: filter.lastName, label: "Last Name" },
        email: { data: filter.email, label: "Email" },
        dob: { data: filter.dob, label: "DateOfBirth" },
        phoneNumber: { data: filter.phoneNumber, label: "Phone" },
        clientID: { data: filter.Client, label: "Client" },
        siteID: { data: filter.Show, label: CONFIG.isWhiteLabel ? "Location" : "Show" },
        site_name: { data: filter.site_name, label: CONFIG.isWhiteLabel ? "Location" : "Show" },
        labID: { data: filter.Lab, label: "Lab" },
        status: { data: filter.status, label: "Status" },
        result: { data: filter.result, label: "Result" },
        test_type: { data: filter.test_type, label: CONFIG.isWhiteLabel ? "Test" : "Test Type" },
        reason: { data: filter.reason, label: "Reason" },
        sequenceNo: { data: filter.sequenceNo, label: `${stringConstant.sequenceNo}` },
        tester_name: { data: filter.tester_name, label: "Tester" },
        emailSend: { data: filter.emailSend, label: "Result Sent" },
        updatedAt: { data: undefined, label: "Date Processed" },
        createdAt: { data: undefined, label: "Date Collected" },
        resultDate: { data: undefined, label: "Date Processed" },
        batch: { data: undefined, label: "Date Tested" },
        validUntil: { data: undefined, label: "Valid Until" },
        isQuarantined: { data: filter.isQuarantined, label: "Quarantined" },
        show: { data: undefined, label: CONFIG.isWhiteLabel ? "Location" : "Show" },
        resultDates: { data: undefined, label: "Last Positive Test Date" },
        quarantinedStart: { data: undefined, label: "Expected End Date" },
        daysCount: { data: undefined, label: "Days Count" },
        hippaConsent: { data: undefined, label: "Hippa Consent" },
        referenceID: { data: filter.referenceID, label: "ReferenceID" },
        accession_no: { data: filter.accession_no, label: "Accession No" },
        phone_number: { data: filter.phone_number, label: "Phone" },
        sex: { data: filter.sex, label: "Gender" },
        id_number: { data: filter.id_number, label: "ID Number" },
        street: { data: filter.street, label: "Street" },
        city: { data: filter.city, label: "City" },
        showName: { data: filter.showName, label: "Show Name" },
        testSendQty: { data: filter.testSendQty, label: "Send Quantity" },
        isVaccinated: { data: parseBooleanValue(filter.isVaccinated), label: "Vaccinated" },
        vaccinationType: { data: filter.vaccinationType, label: "Vaccination Type" },
        vaccinationDate: { data: filter.vaccinationDate, label: "Vaccination Date" },
        isFalsePositive: { data: filter.isFalsePositive, label: "isFalsePositive" },
        isExternalTest: { data: parseBooleanValue(filter.isExternalTest), label: "External Test" },
        externalTestType: { data: filter.externalTestType, label: "External Test Type" },
        externalTestDate: { data: filter.externalTestDate, label: "External Test Date" },
        externalTestResult: { data: filter.externalTestResult, label: "External Test Resut" },
        step: { data: PRE_REGISTRATION_STATUS[filter.step], label: "Status" },
        orderStatus: { data: filter.orderStatus, label: "Order Status" },
        barcode: { data: filter.barcode, label: "Accession/Barcode" },
        employeeType: { data: filter.employeeType, label: "Employee Type" },
        capcity: { data: filter.capacity, label: "Capacity" },
        designation: { data: filter.designation, label: "Designation" },
        whiteGlove: { data: filter.whiteGlove, label: "White Glove" },
        patternTestAnswer: { data: filter.patternTestAnswer, label: "Pattern Test" },
        lastUsed: { data: filter.lastUsed, label: "Last Test Used" },
      };
      const arr = Object.keys(data[0]).map((c) => filters[c]?.label || "");
      const columnsWidth = [];
      for (let i = 0; i < arr.length; i++) {
        if (
          arr[i] === "Date Processed" ||
          arr[i] === "Date Collected" ||
          arr[i] === "Tester" ||
          arr[i] === "Date Tested" ||
          arr[i] === "Hippa Consent" ||
          arr[i] === "Phone"
        ) {
          columnsWidth.push({ wch: 15 });
          continue;
        }
        if (
          arr[i] === "Last Positive Test Date" ||
          arr[i] === "Expected End Date" ||
          arr[i] === "Email" ||
          arr[i] === "Employee Type" ||
          arr[i] === "Last Test Used"
        ) {
          columnsWidth.push({ wch: 18 });
          continue;
        }
        columnsWidth.push({ wch: 12 });
      }

      function gapFilteredVal(filters) {
        var str = "";
        for (const [key, value] of Object.entries(filters)) {
          if (key === "emailSend" && value.data !== undefined && value.data.length > 0) {
            const val = value.data == "1" ? "✔" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (value.data !== undefined && value.data.length > 0) {
            str += `${value.label} - ${value.data}, `;
          }
        }
        return str;
      }
      // Function for gap in excel row skip rows
      // function create_gap_rows(ws, nrows) {
      //   var ref = XLSX.utils.decode_range(ws["!ref"]); // get original range
      //   ref.e.r += nrows; // add to ending row
      //   ws["!ref"] = XLSX.utils.encode_range(ref); // reassign row
      // }
      const sortedVal = filters[sortBy];
      var wsrows = [
        { hpx: summary ? 180 : timeFilter ? 100 : 80, level: 1 }, // "points"
      ];

      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: colLength - 1 } }];

      const fileName = `${CONFIG.basename}.xlsx`;
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let dateRange = "";
      if (timeFilter) {
        const { start, end } = timeFilter;
        dateRange =
          start && end
            ? `Date Range: ${moment(start).format("MMM DD, YYYY")} to ${moment(end).format("MMM DD, YYYY")}`
            : `Date Range:`;
      }
      const reportTitle = title || "Complete Tests Report";
      const filterVal = filter
        ? `Filtered By: ${Object.keys(filter).length > 0 ? gapFilteredVal(filters) : "None"}`
        : "";
      const sorted = filter ? `Sorted By: ${sortedVal ? sortedVal.label : "None"}` : "";

      const orderDetails = summary
        ? `Total Tests Ordered: ${summary.totalOrders ?? 0}, Total Attendees: ${summary.totalAttendees ?? 0},
      Total Attendees Get Qr: ${summary.totalAttendeesQr ?? 0}, Total Tests Shipped: ${summary.totalShipped ?? 0},
      Total Tests Received: ${summary.totalReceived ?? 0}`
        : "";

      const ws = !CONFIG.isWhiteLabel
        ? dateRange.length > 0
          ? XLSX.utils.aoa_to_sheet(
              [[`${reportTitle} \n ${dateRange} \n ${filterVal} \n ${sorted} \n ${orderDetails}`]],
              { origin: "A1" }
            )
          : XLSX.utils.aoa_to_sheet([[`${reportTitle} \n ${filterVal} \n ${sorted} \n ${orderDetails}`]], {
              origin: "A1",
            })
        : XLSX.utils.aoa_to_sheet([arr], { origin: 0 });
      if (!CONFIG.isWhiteLabel) {
        XLSX.utils.sheet_add_aoa(ws, [arr], { origin: -1 });
      }
      XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });

      let columnMaxWidths = {};

      // get all header name from data obejct
      const headers = Object.keys(data[0]);

      // set the max number of character length against each column data
      data.forEach((item) => {
        headers.forEach((header) => {
          const cellValue = item[header];
          const cellContentLength = String(cellValue).length;
          if (!columnMaxWidths[header] || cellContentLength > columnMaxWidths[header]) {
            columnMaxWidths[header] = cellContentLength;
          }
        });
      });

      // now push the column character width as per the max character length of each column data
      const columnWidths = [];
      
      headers.forEach((header) => {
        let val = parseInt(columnMaxWidths[header]);
        if (header === "emailSend") {
          val = 15;
        }
        columnWidths.push({ wch: val });
      });

      if (!CONFIG.isWhiteLabel) {
        ws["!rows"] = wsrows;
        ws["!merges"] = merge;
        ws["!cols"] = columnsWidth;
        ws["A1"].s = {
          alignment: {
            horizontal: "center",
            wrapText: true,
          },
          font: {
            name: "Calibri",
            sz: 16,
            bold: true,
            color: { rgb: "#000" },
          },
        };
        for (let i = 65; i < colLength + 65; i++) {
          ws[`${String.fromCharCode(i)}2`].s = {
            alignment: {
              horizontal: "center",
            },
            font: {
              name: "Calibri",
              sz: 12,
              bold: true,
              underline: true,
              color: { rgb: "#000" },
            },
          };
        }
      } else {
        for (let i = 65; i < colLength + 65; i++) {
          const colLetter = String.fromCharCode(i);
          const colHeader = `${colLetter}1`;
          ws[colHeader].s = {
            alignment: {
              horizontal: "center",
            },
            font: {
              name: "Calibri",
              sz: 12,
              bold: true,
              underline: true,
              color: { rgb: "#000" },
            },
          };
        }
        // set the newly set column width
        ws["!cols"] = columnWidths;
        
      }

      // table  each cells styles
      //
      // for (let i = 65; i < colLength + 65; i++) {
      //   for(let j=0; j<rowLength; j++){
      //   ws[`${String.fromCharCode(i)}${j+3}`].s = {
      //     alignment: {
      //       horizontal: "center",
      //       wrapText: true
      //     },
      //   };
      // }
      // }
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileContent = new Blob([excelBuffer], { type: fileType });
      setList([]);
      setLoading(false);
      try {
        await FileSaver.saveAs(fileContent, fileName);
        appContext.showSuccessMessage("Excel file export successfully");
      } catch (err) {
        appContext.showErrorMessage("file can't be expored");
      }
    }
  };

  return <Icon handleClick={fetchData} title={"Export to Excel"} label="Export" iconType={"XLSIcon"} />;
};

export default ExportToExcelData;
