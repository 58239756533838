import React, { useContext, useState, useEffect } from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import {
  formatPhoneNumber,
  formatZipCode,
  getDemoGraphics,
  getAge,
  formatDateOfBirthDOB,
  formatDateMDY,
  currentDate,
  formatPDFName,
  toTitleCase,
} from "utils";
import { TEST_TYPE_DISCLAIMER_KEY } from "constant";
import { logoImage } from "constant";
import qrImage from "assets/img/biolab_qr.png";
import ArialRegular from "assets/fonts/arial.ttf";
import ArialBold from "assets/fonts/arial_bold.ttf";
import { formatTimeFromDate, formatAddress } from "utils";
import { RESULTS_COLOR } from "constant";
import api from "api";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { formatPhone } from "utils";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialRegular, fontWeight: "normal" },
    { src: ArialBold, fontWeight: "bold" },
  ],
});

const parseSrNoObjectRecviedDate = (test) => {
  try {
    const arr = JSON.parse(test.sr_no);
    return arr.length > 1 ? arr[1]?.date : arr[0]?.date;
  } catch (error) {
    return test.createdAt;
  }
};

const BiolabPdf = ({ test, testsLab, parseTestResultForPDF, employeeID }) => {
  const [clientInfo, setClientInfo] = useState();
  let demos = getDemoGraphics(test);

  useEffect(() => {
    if (test?.clientID) {
      getClientByID(test);
    }
  }, [test]);

  const getClientByID = async (test) => {
    let clientModal = await api.getClientById(test?.clientID);
    setClientInfo(clientModal);
  };

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  const getLabDisclaimer = () => {
    if (testsLab) {
      const disclaimerKey = TEST_TYPE_DISCLAIMER_KEY[test.test_type.replace(/(:|\s+)/g, "_")] || "testing_notes";
      const notes = testsLab[disclaimerKey];
      if (notes) return notes;
    }
    return "N/A";
  };

  const testTypeLabel = () => {
    if (test.test_type === "RapidFlu" || test.test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    return `2019-nCoV rRT-${test.test_type}`;
  };
  const baseTestTypeLabel = () => {
    if (test.test_type === "RapidFlu" || test.test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    return `2019-nCoV`;
  };
  return (
    <Document title={`${demos.firstName} ${demos.lastName}`}>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.flexRow, flex: 0.8 }}>
          <Image style={{ width: "220px", opacity: 1 }} src={logoImage} />
          <View style={{ ...styles.flexRow }}>
            <View style={{ display: "flex", alignItems: "flex-start", fontSize: 11 }}>
              <Text style={{ textTransform: "uppercase" }}>{testsLab?.street || ""}</Text>
              <Text style={{ textTransform: "uppercase" }}>{testsLab?.city_state_zip || ""}</Text>
              <Text>Tel:   {formatPhone(testsLab?.phone_number) || ""}</Text>
              <Text>Fax:  {formatPhone(testsLab?.fax_number)}</Text>
            </View>
            {/* <Image src={qrImage} style={styles.qrCode} /> */}
          </View>
        </View>
        <View style={{ border: "1px solid black", position: "relative", flex: 5 }}>
          <View style={{ ...styles.flexRow, lineHeight: 1.5 }}>
            <View style={{ width: "50%", padding: "15px 20px", borderRight: 1, borderColor: "black", fontSize: 10 }}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text>Acc:</Text>
                <View style={{ marginLeft: 5 }}>
                  <Text style={{ ...styles.textUppercase, ...styles.bold, color: "blue" }}>
                    {clientInfo?.name || ""}
                  </Text>
                  <Text style={{ textTransform: "uppercase" }}>{clientInfo?.street || ""}</Text>
                  <Text style={{ textTransform: "uppercase" }}>{formatAddress(clientInfo)}</Text>
                  <Text>{formatPhone(clientInfo?.phone_number)}</Text>
                </View>
              </View>
              <View style={{ display: "flex", marginTop: 10, alignItems: "center", flexDirection: "row" }}>
                <Text>Phys:</Text>
                <Text style={{ ...styles.textUppercase, ...styles.bold, marginLeft: 5 }}>
                  {testsLab?.lab_director || "-"}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", ...styles.flexRow, fontSize: 10, justifyContent: "space-between" }}>
              <View style={{ flex: 2, width: "70%", padding: 10, paddingLeft: 20 }}>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Patient:</Text>
                  <Text style={{ color: "blue", fontWeight: "bold" }}>{formatPDFName(demos)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>DOB:</Text>
                  <Text>{formatDateOfBirthDOB(demos.dob)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Phone:</Text>
                  <Text>{formatPhone(test.phoneNumber)}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>ID#:</Text>
                  <Text>{demos.idNumber || test.empID || ""}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Route#:</Text>
                  <Text>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Fasting:</Text>
                  <Text>-</Text>
                </View>
              </View>
              <View style={{ flex: 1.2, width: "30%", padding: 10, paddingRight: 20 }}>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>&nbsp;</Text>
                  <Text>&nbsp;</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Age:</Text>
                  <Text>{`${getAge(demos.dob)} Years`}</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Sex:</Text>
                  <Text>F</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Room#:</Text>
                  <Text>0</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ minWidth: 60 }}>Page:</Text>
                  <Text>1</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...styles.hr }} />
          <View style={{ ...styles.flexRow, fontSize: 10, flexDirection: "row", fontSize: 10, lineHeight: 1.5 }}>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Acc:</Text>
                <Text>{test.barcode}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Chart#:</Text>
                <Text>-</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>First reported on:</Text>
                <Text style={{ paddingLeft: 10 }}></Text>
              </View>
            </View>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Coll. Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(test.createdAt)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Coll. Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(test.createdAt)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
            </View>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Recv. Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(parseSrNoObjectRecviedDate(test))}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Recv. Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(parseSrNoObjectRecviedDate(test))}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Final Report date:</Text>
                <Text style={{ paddingLeft: 10 }}></Text>
              </View>
            </View>
            <View style={{ width: "25%", padding: 10, paddingBottom: 10, paddingTop: 5, flex: 1 }}>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Print Date:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>Print Time:</Text>
                <Text style={{ paddingLeft: 10 }}>{formatTimeFromDate(test.resultDateTime)}</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={{ minWidth: 60 }}>{formatDateMDY(test.resultDateTime)}</Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.flexRow, border: 1, backgroundColor: "lightgray" }}>
            <View style={{ flex: 1.4, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Test Name</Text>
            </View>
            <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Normal</Text>
            </View>
            <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Out of Range</Text>
            </View>
            <View style={{ flex: 1, padding: 5, borderRight: 1, borderColor: "black" }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Normal Range</Text>
            </View>
            <View style={{ flex: 0.5, padding: 5 }}>
              <Text style={{ ...styles.bold, textAlign: "center" }}>Units</Text>
            </View>
          </View>

          <Text style={{ padding: "5px 10px", fontSize: 10, fontWeight: "bold" }}>Report Status: FINAL</Text>
          <Text style={{ padding: "0px 10px", fontSize: 10, fontWeight: "bold" }}>
            Source: {toTitleCase(demos?.swabtype || "Nasal")}
          </Text>

          {demos.comment && (
            <View style={{ flexDirection: "row", paddingHorizontal: 10 }}>
              <Text style={{ fontSize: 10, flex: 1, fontWeight: "bold" }}>Comment: </Text>
              <Text style={{ fontSize: 10, flex: 6 }}>{demos.comment}</Text>
            </View>
          )}

          <Text style={{ ...styles.textUppercase, ...styles.bold, color: "blue", padding: "5px 10px" }}>COVID-19</Text>
          <View style={{ ...styles.flexRow, paddingHorizontal: 10, fontSize: 10 }}>
            <Text style={{ flex: 1.4, textAlign: "left" }}>COVID-19 MOLECULAR RT-PCR</Text>
            <Text
              style={{
                ...styles.bold,
                color: RESULTS_COLOR[test.result?.toLowerCase()],
                flex: 1,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {test.result?.toLowerCase() === "negative" && parseTestResultForPDF(test)}
            </Text>
            <Text
              style={{
                ...styles.bold,
                color: RESULTS_COLOR[test.result?.toLowerCase()],
                flex: 1,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {test.result?.toLowerCase() !== "negative" && parseTestResultForPDF(test)}
            </Text>
            <Text style={{ flex: 1, textAlign: "center", textTransform: "uppercase" }}>Not Detected</Text>
            <Text style={{ flex: 0.5, textAlign: "center" }}></Text>
          </View>
          <View style={{ marginLeft: "120px", marginTop: 15 }}>
            {testsLab.invalid_notes && test.result && test.result.toLowerCase() === "invalid" && (
              <Text
                style={{
                  ...styles.infoText,
                  fontFamily: "Helvetica-Bold",
                  paddingHorizontal: 40,
                  marginBottom: "5px",
                  fontSize: 10,
                }}
              >
                {testsLab.invalid_notes}
              </Text>
            )}
            {testsLab.indeterminate_notes && test.result && test.result.toLowerCase() === "indeterminate" && (
              <Text
                style={{
                  ...styles.infoText,
                  fontFamily: "Helvetica-Bold",
                  paddingHorizontal: 40,
                  marginBottom: "5px",
                  fontSize: 10,
                }}
              >
                {testsLab.indeterminate_notes}
              </Text>
            )}
            <Text style={{ ...styles.infoText, paddingHorizontal: 40, fontSize: 9 }}>{getLabDisclaimer()}</Text>
          </View>
          {test.result?.toLowerCase() !== "negative" && (
            <>
              <Text
                style={{
                  padding: 10,
                  color: "blue",
                  ...styles.bold,
                  fontSize: 11,
                }}
              >
                **********************************************OUT OF RANGE
                SUMMARY*******************************************
              </Text>
              <View style={{ ...styles.flexRow, paddingHorizontal: 10, fontSize: 10 }}>
                <Text style={{ flex: 1.4, textAlign: "center" }}>COVID-19 MOLECULAR RT-PCR</Text>
                <Text
                  style={{
                    ...styles.bold,
                    color: RESULTS_COLOR[test.result?.toLowerCase()],
                    flex: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {test.result?.toLowerCase() === "negative" && parseTestResultForPDF(test)}
                </Text>
                <Text
                  style={{
                    ...styles.bold,
                    color: RESULTS_COLOR[test.result?.toLowerCase()],
                    flex: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {test.result?.toLowerCase() !== "negative" && parseTestResultForPDF(test)}
                </Text>
                <Text style={{ flex: 1, textAlign: "center", textTransform: "uppercase" }}>Not Detected</Text>
                <Text style={{ flex: 0.5, textAlign: "center" }}></Text>
              </View>
            </>
          )}
          <View style={styles.container}>
            <View style={styles.line} />
            <Text style={styles.text}>end of report</Text>
            <View style={styles.line} />
          </View>
          <View
            style={{
              position: "absolute",
              bottom: "10",
              left: "10",
              width: "100%",
            }}
            fixed
          >
            <View style={{ flexDirection: "row", alignItems: "center", fontSize: 11 }}>
              <Text>Labartory Director: </Text>
              <Text style={{ ...styles.bold, textTransform: "uppercase" }}>{testsLab?.lab_director || ""}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 12,
    fontFamily: "Arial", // Use the Arial font for the entire document
  },
  qrCode: {
    width: 100,
  },
  bold: {
    fontWeight: "bold",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  infoText: {
    fontSize: 11,
  },
  infoTextRightBorder: {
    fontSize: 11,
    fontStyle: "bold",
  },
  italicText: {
    fontSize: 11,
    fontStyle: "italic",
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 9,
    bottom: 15,
    right: 22,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderLeftWidth: 1,
    borderLeftColor: "black",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-around",
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%",
  },
  hrTwo: {
    borderBottomColor: "black",
    borderBottomWidth: 2,
    width: "100%",
  },
  address: {
    // marginLeft: 50,
    marginVertical: 20,
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: "black",
  },
  waterMark: {
    opacity: 0.05,
    fontSize: "70px",
    color: "black",
    background: "#ccc",
    position: "absolute",
    cursor: "default",
    top: "45%",
    left: "30%",
    transform: "Translate(-50%, -50%)",
  },
  container: {
    width: "60%",
    marginHorizontal: "auto",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    display: "inline-block",
    width: "40%",
    flex: 1,
  },
  text: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 11,
    flex: 1,
  },
  textUppercase: {
    textTransform: "uppercase",
  },
});

export default BiolabPdf;
