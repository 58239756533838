import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";

const PhoneNoInput = (props) => {
  const { handleChange, value, error, disabled = false, cssCode } = props;
  const [defaultCountryCode, setDefaultCountryCode] = useState("US");

  useEffect(() => {
    if (error) error(validatePhone());
  });

  useEffect(() => {
    if (value && isPossiblePhoneNumber(value)) {
      let countryCode = parsePhoneNumber(value)?.country;
      if (countryCode) setDefaultCountryCode(countryCode);
    }
  }, [value]);

  const handleValueChange = () => {
    if (isPossiblePhoneNumber(value)) {
      console.log("calling handle change", value);
      handleChange;
    }
  };

  const validatePhone = () => {
    if (value) {
      if (isPossiblePhoneNumber(value)) {
        return "";
      }
      return "Invalid phone number";
    }
    return "";
  };

  return (
    <PhoneInput
      international
      countryCallingCodeEditable={false}
      className={cssCode ? `${cssCode}` : ""}
      defaultCountry={defaultCountryCode || "US"}
      placeholder="Phone"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      // onChange={handleValueChange}
      error={validatePhone()}
    />
  );
};

export default PhoneNoInput;
