import React, { useState, useContext } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { AppContext } from "../../context/app-context";

const LabBarCodeAlertSetting = () => {
  const appContext = useContext(AppContext);

  const getAlertValue = () => {
    if (appContext.labs) {
      const barcodelabs = appContext.labs.filter((l) => l.barCodeProvided === true);
      if (barcodelabs.length > 0) return barcodelabs[0].barCodeAlertLimit || 0;
    }
    return 0;
  };
  const [limit, setLimit] = useState(getAlertValue());
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const alertVal = getAlertValue();
    if (alertVal > 0) {
      setLimit(alertVal);
    }
  }, [appContext.labs]);

  const onHandleSave = async () => {
    setIsEdit(false);
    await API.updateBarCodeAlert(limit);
    appContext.resetLabs();
  };

  return (
    <Container fluid>
      <div className="shows-screen-parent">
        <Row className="mx-0">
          <Col md="12">
            <Card className="strpied-tabled-with-hover bg-transparent">
              <Card.Header className="shows-screen-wrapper">
                <Row className="pb-4">
                  <Col md-="12">
                    <Card.Title>
                      <div className="">Lab Settings</div>
                    </Card.Title>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 mt-0">
                <div className="formWrapper">
                  <div>Barcode Alert Limit:</div>
                  <div>
                    <input
                      value={limit}
                      disabled={!isEdit}
                      className="modalInput"
                      placeholder="Alert Limit"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                  <div>
                    {!isEdit && (
                      <Button variant="primary" className="modalButtons" onClick={() => setIsEdit(true)}>
                        Edit
                      </Button>
                    )}
                    {isEdit && (
                      <Button variant="primary" className="modalButtons" onClick={onHandleSave}>
                        Update
                      </Button>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default LabBarCodeAlertSetting;
