const TABLE_COLUMN_TYPES = {
  shortDate: "shortDate",
  text: "text",
  upperCaseText: "upperCaseText",
  email: "email",
  phone: "phone",
  currency: "currency",
  image: "image",
  insuranceProvider: "insuranceProvider",
};

export const EMP_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "required",
    title: "Required",
    textAlign: "left",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "emailSent",
    title: "Email Sent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "completed",
    title: "Completed",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];
