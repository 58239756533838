import React, { useState } from "react";
import { CONFIG } from "constant";

const SequenceNo = (props) => {
  const { num, test, handleEdit, showLogs } = props;
  const parseSrNoObject = (test) => {
    try {
      let arr = [];
      try {
        arr = JSON.parse(test.sr_no);
      } catch (er) {
        return test.barcode;
      }
      if (arr.length > 0) {
        return arr.map((r) => r.accession_no).join("\n");
      }
      return test.barcode;
    } catch (e) {
      return test.barcode;
    }
  };

  return (
    <>
      <td
        className="ellipsis accessionListNo"
        style={{ textAlign: "center", cursor: "Pointer" }}
        title={parseSrNoObject(test)}
        onClick={() => showLogs(test)}
      >
        {num}
        {handleEdit && CONFIG.isWhiteLabel && !test.result && (
          <i
            className="fas fa-edit accessionEdit"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(test);
            }}
          />
        )}
      </td>
    </>
  );
};

export default SequenceNo;
