import PopoverStick from "components/PopoverStick";
import { CONFIG } from "constant";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { formatLogDate, capitalizeLetter } from "utils";
import API from "api";
import { Storage } from "aws-amplify";
import FullImageView from "components/FullPageImageView";

const TestLogsModal = (props) => {
  const { handleClose, test, setSuccessMessageText } = props;
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const getSequenceNo = () => {
    if (data && data.length > 0) return CONFIG.isWhiteLabel ? data[0]?.barcode : data[0].sequenceNo;
    return "";
  };

  const showTestLogs = async () => {
    let logs = await API.getTestLogs(test.sequenceNo);
    logs = logs.map((m) => {
      if (m.slug && m.slug.toLowerCase() === "email") {
        return { ...m, newValue: "Result Emailed" };
      }
      return { ...m };
    });
    setData([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  useEffect(() => {
    showTestLogs();
  }, []);

  const LABELS = {
    AccessionNoChanged: "Accession No. Changed",
  };

  const getActionDetails = (msg) => {
    let val = msg.slug || msg.action;
    return LABELS[val] || val;
  };

  const handleClickOnImage = async (log) => {
    if (!log.file) return;
    try {
      const res = await Storage.get(log.file);
      setImage(res);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Test Logs {CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    Sr#
                  </th>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Date</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Action</span>
                    </div>
                  </th>

                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>Event</span>
                    </div>
                  </th>

                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>User</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((msg, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatLogDate(msg.createdAt)}</td>
                        {msg.slug === "Test Updated" ? (
                          <PopoverStick
                            item={msg.slug}
                            values={Object.keys(msg.changeObject)}
                            changeObject={msg.changeObject}
                          />
                        ) : (
                          <td style={{ textAlign: "center" }}>{getActionDetails(msg)}</td>
                        )}

                        <td
                          className={
                            msg.file && "linkedTextU"
                          }
                          style={{ textAlign: "center" }}
                          onClick={() => handleClickOnImage(msg)}
                        >
                          {msg.file
                            ? msg.message
                            : capitalizeLetter(msg.prevValue || msg.oldValue || "") &&
                              capitalizeLetter(msg.newValue || msg.result || "")
                            ? ` ${capitalizeLetter(msg.prevValue || msg.oldValue || "-")} to ${capitalizeLetter(
                                msg.newValue || msg.result || "-"
                              )}`
                            : capitalizeLetter(msg.newValue || msg.result || "-")}
                        </td>
                        <td style={{ textAlign: "center" }}>{msg.userName || "N/A"}</td>
                        {window.location.hostname === "localhost" && (
                          <td
                            onClick={async () => {
                              await API.deleteLogRecord(msg);
                              setSuccessMessageText("delete Successfully");
                              // appContext.showSuccessMessage("delete Successfully");
                              await showTestLogs();
                            }}
                          >
                            <i className="fas fa-trash cursor-pointer" />
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
      {image && <FullImageView image={image} handleClose={() => setImage("")} />}
    </Modal>
  );
};

export default TestLogsModal;
